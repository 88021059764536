<template>
  <div>
    <h4 v-if="openMode == 'new' || openMode == 'incompleteLoad'">
      {{ title }}
    </h4>
    <b-overlay
      :show="isWorking || isSearching"
      :rounded="overlayConfig.rounded"
      :variant="overlayConfig.variant"
      :opacity="overlayConfig.opacity"
      :blur="overlayConfig.blur"
    >
      <template #overlay>
        <div class="mt-2">
          <div style="text-align: center">
            <span class="spinner-border " />
          </div>
          <div style="text-align: center">
            <small>{{ overlayConfig.message }}</small>
          </div>
          <hr>
          <b-progress
            v-if="progressData.progressAdjuntos > 0 && isSavingAdjuntos"
            height="1rem"
            width="450px"
            :max="progressData.progressAdjuntos"
          >
            <b-progress-bar :value="progressData.progressAdjuntosDone">
              <span>Adjuntando {{ progressData.progressAdjuntosDone }} / {{ progressData.progressAdjuntos }}</span>
            </b-progress-bar>
          </b-progress>
        </div>
      </template>
      <validation-observer ref="refAdherente">
        <b-row>
          <b-col md="12">
            <b-table
              id="tblAfiliadoAdherente"
              ref="refTblAfiliadoAdherente"
              :busy="isWorking"
              :fields="fieldsAfiliadoAdherente"
              :items="tableDataAfiliadoAdherente"
              outlined
              show-empty
              small
              hover
              sticky-header="70vh"
              :no-border-collapse="true"
              empty-text="No existe grupo familiar cargado"
              @row-dblclicked="dobleClik"
            >
              <template v-slot:table-busy>
                <div class="text-center">
                  <b-spinner />
                </div>
              </template>
              <template #cell(fechaNacimiento)="data">
                {{ formatDateToJavaT(data.item.fechaNacimiento, 'DDMMYYYY', 'DD/MM/YYYY') }}
              </template>
              <template #cell(nombreApellido)="data">
                {{ data.item.apellido + ', ' + data.item.nombre }}
              </template>
              <template #cell(actions)="row">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <b-button
                    v-if="usarDetalleIntegrado"
                    v-b-tooltip.hover.righttop="'Ver detalle'"
                    size="sm"
                    @click="row.toggleDetails"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    v-if="permisosUpd.includes(nameModulo) && isEditable"
                    v-b-tooltip.hover.righttop="'Editar adherente'"
                    size="sm"
                    :disabled="!isEditable"
                    @click="editDetalleAfiliadoAdherente(row.item, row.index, $event.target)"
                  >
                    <font-awesome-icon icon="fa-regular fa-pen-to-square" />
                  </b-button>
                  <b-button
                    v-if="permisosDel.includes(nameModulo) && isEditable"
                    v-b-tooltip.hover.righttop="'Eliminar adherente'"
                    size="sm"
                    :disabled="!isEditable"
                    @click="deleteAfiliadoAdherente(row.item, row.index, $event.target)"
                  >
                    <font-awesome-icon icon="fa-solid fa-trash" />
                  </b-button>
                </div>
              </template>
              <template #row-details="row">
                <detalle-grupo-familiar
                  :item-index="row.index"
                  :detalle-afiliado-adherente="row.item"
                  @changeItemIndex="changeItemIndex"
                />
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="permisosIns.includes(nameModulo)"
            md="12"
            class="text-right"
          >
            <b-button
              variant="primary"
              :disabled="!isEditable"
              @click="openModalAddAfiliadoAdherente"
            >
              Agregar adherente
            </b-button>
          </b-col>
        </b-row>
        <br>
      </validation-observer>
    </b-overlay>
    <div v-if="showAfiliadoAdherenteModal">
      <grupo-familiar-modal
        :show-afiliado-adherente-modal="showAfiliadoAdherenteModal"
        :open-mode-modal="openModeModal"
        :modal-data-afiliado-adherente="modalDataAfiliadoAdherente"
        :id-afiliado="idAfiliado"
        @agregarAfiliadoAdherente="agregarAfiliadoAdherente"
        @editarAfiliadoAdherente="editarAfiliadoAdherente"
        @setAfiliadoAdherente="setAfiliadoAdherente"
        @closeModalAfiliadoAdherente="closeModalAfiliadoAdherente"
      />
    </div>
    <b-row>
      <b-col md="4">
        <b-button
          id="btnVolverAfiliados"
          v-b-tooltip.hover
          title="Volver a listado afiliados"
          variant="light"
          squared
          @click="()=>backAfiliado()"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button></b-col>
      <b-col
        md="2"
        class="text-right px-0"
      >
        <b-button
          v-if="isWizard"
          id="btnPasoAnterior"
          v-b-tooltip.hover
          title="Anterior"
          variant="outline-primary"
          squared
          style="primary"
          :disabled="isWorking || isFirstStep"
          @click="anteriorWizard()"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
      </b-col>
      <b-col
        md="2"
        class="text-left  px-0"
      >
        <b-button
          v-if="isWizard"
          id="btnPasoPosterior"
          v-b-tooltip.hover
          squared
          variant="outline-primary"
          title="Siguiente"
          type="submit"
          :disabled="isWorking || !isEditable"
          @click.prevent="posteriorWizard()"
        >
          <span />
          <feather-icon
            v-if="!isWorking"
            icon="ArrowRightIcon"
          />
          <span
            v-if="isWorking"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
      <b-col
        v-if="!isWizard"
        md="4"
        class="text-right"
      >
        <b-button
          v-if="permisosUpd.includes(nameModulo) && isEditable"
          id="btnGuardarDatosAdherente"
          v-b-tooltip.hover
          class="text-end"
          variant="primary"
          squared
          title="Guardar grupo familiar"
          type="submit"
          :disabled="!isEditable"
          @click.prevent="validarFormulario"
        >
          <font-awesome-icon
            v-if="!isWorking"
            icon="fa-solid fa-floppy-disk"
          />
          <span
            v-if="isWorking"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import GrupoFamiliarModal from './GrupoFamiliarModal.vue'
import DetalleGrupoFamiliar from './DetalleGrupoFamiliar.vue'
import { afiliadoAdherenteResource } from '@/services/afiliadoAdherenteResource'
import { afiliadoAdjuntoAdherenteResource } from '@/services/afiliadoAdjuntoAdherenteResource'

export default {
  components: {
    GrupoFamiliarModal,
    DetalleGrupoFamiliar,
  },
  props: {
    afiliadoDto: {
      type: Object,
      required: false,
      default: null,
    },
    idAfiliado: {
      type: String,
      required: false,
      default: null,
    },
    isWizard: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFirstStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    openMode: {
      type: String,
      required: true,
    },
    editarEnableDisable: {
      type: Boolean,
      required: false,
    },
    isLastStep: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      title: 'Nuevo Afiliado',
      nameModulo: 'ABM_AFILIADOS',
      isWorking: false,
      isSearching: false,
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
      usarDetalleIntegrado: true,
      showAfiliadoAdherenteModal: false,
      isSavingAdjuntos: false,
      openModeModal: null,
      adjuntosList: [],
      originalDataAfiliadoAdherente: {},
      progressData: {
        progressAdjuntos: 0,
        progressAdjuntosDone: 0,
      },
      tableDataAfiliadoAdherenteTemporal: [],
      tableDataAfiliadoAdherente: [],
      fieldsAfiliadoAdherente: [
        {
          key: 'numeroDocumento', label: 'DNI',
        },
        {
          key: 'nombreApellido', label: 'Nombre',
        },
        {
          key: 'genero.nombre', label: 'Género',
        },
        {
          key: 'fechaNacimiento', label: 'F Nacimiento',
        },
        {
          key: 'edad', label: 'Edad',
        },
        {
          key: 'relacionFamiliar.nombre', label: 'Parentesco',
        },
        { key: 'actions', label: 'Acciones', thStyle: { width: '10%' } },
      ],
    }
  },
  computed: {
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.openMode === 'incompleteLoad' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        user: 'currentUser',
      },
    ),
  },
  created() {
    if (this.openMode !== 'new') {
      this.findByIdAfiliado()
    }
  },
  methods: {
    backAfiliado() {
      this.$router.push('/afiliados')
    },
    anteriorWizard() {
      this.$emit('previous-step', this.afiliadoDto)
    },
    posteriorWizard() {
      this.validarFormulario()
    },
    async validarFormulario() {
      const promises = this.tableDataAfiliadoAdherenteTemporal.map(async element => {
        if (element.id.toString().includes('generated')) {
          // eslint-disable-next-line no-param-reassign
          element.id = null
        }
        if (element.id === null) {
          await this.saveAllAfiliadoAdherente([element])
        } else {
          await this.updateAllAfiliadoAdherente([element])
        }
        await this.findByIdAfiliado()
        this.tableDataAfiliadoAdherenteTemporal = []
      })

      await Promise.all(promises)
      this.$emit('next-step')
    },
    async saveAfiliadoAdherente(afiliadoAdherente) {
      this.isWorking = true
      // eslint-disable-next-line no-param-reassign
      afiliadoAdherente.fechaNacimiento = this.formatDateToJavaT(afiliadoAdherente.fechaNacimiento, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
      // eslint-disable-next-line no-param-reassign
      afiliadoAdherente.afiliadoId = this.afiliadoDto.id
      try {
        const result = await afiliadoAdherenteResource().saveAdherente(afiliadoAdherente)
        return result
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.isWorking = false
      }
    },

    async updateAfiliadoAdherente(afiliadoAdherente) {
      this.isWorking = true
      // eslint-disable-next-line no-param-reassign
      afiliadoAdherente.fechaNacimiento = this.formatDateToJavaT(afiliadoAdherente.fechaNacimiento, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
      // eslint-disable-next-line no-param-reassign
      afiliadoAdherente.afiliadoId = this.afiliadoDto.id
      try {
        const result = await afiliadoAdherenteResource().updateAdherente(afiliadoAdherente)
        return result
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.isWorking = false
      }
    },
    async saveAllAfiliadoAdherente(afiliadoAdherente) {
      this.isWorking = true
      // eslint-disable-next-line no-restricted-syntax
      for (const adherente of afiliadoAdherente) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const result = await this.saveAfiliadoAdherente(adherente)
          this.generarAdjuntos(adherente.adjuntos, result.id)
        } catch (error) {
          console.error('Error al guardar adherente:', error)
        }
      }
      this.isWorking = false
    },

    async updateAllAfiliadoAdherente(afiliadoAdherente) {
      this.isWorking = true
      // eslint-disable-next-line no-restricted-syntax
      for (const adherente of afiliadoAdherente) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const result = await this.updateAfiliadoAdherente(adherente)
          this.generarAdjuntos(adherente.adjuntos, result.id)
        } catch (error) {
          console.error('Error al actualizar adherente:', error)
        }
      }
      this.isWorking = false
    },
    async findByIdAfiliado() {
      this.isWorking = true
      afiliadoAdherenteResource().findByAfiliadoAdherente(this.afiliadoDto.id)
        .then(resultListAdherentes => {
          resultListAdherentes.forEach(adherente => {
            // eslint-disable-next-line no-param-reassign
            adherente.edad = this.calcularEdad(adherente.fechaNacimiento)
          })
          this.tableDataAfiliadoAdherente = resultListAdherentes
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isWorking = false
        })
    },
    calcularEdad(fechaNacimiento) {
      const match = fechaNacimiento.match(/^(\d{2})-(\d{2})-(\d{4}) (\d{2}:\d{2})$/)

      if (!match) {
        throw new Error('Formato de fecha incorrecto')
      }

      const dia = parseInt(match[1], 10)
      const mes = parseInt(match[2], 10) - 1
      const anio = parseInt(match[3], 10)

      const fNacimiento = new Date(anio, mes, dia)
      const hoy = new Date()
      const edadMilisegundos = hoy - fNacimiento
      const edadFecha = new Date(edadMilisegundos)

      const edad = Math.abs(edadFecha.getUTCFullYear() - 1970)

      return edad
    },
    // eslint-disable-next-line no-unused-vars
    dobleClik(record, index, mouseEvent) {
      this.tableDataAfiliadoAdherente.forEach(item => {
        const objIndex = this.tableDataAfiliadoAdherente.findIndex((obj => obj.id === item.id))
        if (objIndex !== index) {
          // eslint-disable-next-line no-underscore-dangle, no-param-reassign
          item._showDetails = false
        }
      })
      // eslint-disable-next-line no-underscore-dangle
      this.tableDataAfiliadoAdherente[index]._showDetails = !this.tableDataAfiliadoAdherente[index]._showDetails
    },
    agregarAfiliadoAdherente(afiliadoAdherente) {
      this.showAfiliadoAdherenteModal = false
      this.tableDataAfiliadoAdherente.push(afiliadoAdherente)
      this.tableDataAfiliadoAdherenteTemporal.push(afiliadoAdherente)
    },
    editarAfiliadoAdherente(afiliadoAdherente) {
      this.showAfiliadoAdherenteModal = false
      this.tableDataAfiliadoAdherenteTemporal.push(afiliadoAdherente)
    },
    changeItemIndex({ Adherente }) {
      const objIndex = this.tableDataAfiliadoAdherente.findIndex((obj => obj.id === Adherente.id))
      if (objIndex !== -1) {
        this.tableDataAfiliadoAdherente[objIndex] = { ...Adherente }
      } else {
        console.error('Objeto ,objIndex, no encontrado', objIndex)
      }
    },
    closeModalAfiliadoAdherente() {
      this.showAfiliadoAdherenteModal = false
      this.modalDataAfiliadoAdherente = JSON.parse(JSON.stringify(this.originalDataAfiliadoAdherente))
    },
    openModalAddAfiliadoAdherente() {
      this.openModeModal = 'new'
      this.showAfiliadoAdherenteModal = true
      this.modalDataAfiliadoAdherente = null
    },
    editDetalleAfiliadoAdherente(data) {
      this.openModeModal = 'edit'
      this.showAfiliadoAdherenteModal = true
      this.modalDataAfiliadoAdherente = JSON.parse(JSON.stringify(data))
    },
    setAfiliadoAdherente(data) {
      this.showAfiliadoAdherenteModal = false
      const pos = this.tableDataAfiliadoAdherente.findIndex(afiAdh => afiAdh.id === data.id)
      this.tableDataAfiliadoAdherente[pos] = { ...data }
      if (this.tableDataAfiliadoAdherenteTemporal.find(adherenteTemp => adherenteTemp.id === data.id)) {
        const posTemp = this.tableDataAfiliadoAdherenteTemporal.findIndex(afiAdh => afiAdh.id === data.id)
        this.tableDataAfiliadoAdherenteTemporal[posTemp] = { ...data }
      } else {
        this.tableDataAfiliadoAdherenteTemporal.push(data)
      }
      this.$refs.refTblAfiliadoAdherente.refresh()
    },
    guardarMultiplesAdjuntos(documentos) {
      const promesas = []
      let count = 1
      documentos.forEach(async documento => {
        const promesa = this.guardarAdjunto(documento.afiliadoId, documento.afiliadoAdherenteId, documento.adjuntoCategoriaId, documento.adjunto).then(() => {
          this.progressData.progressAdjuntosDone = count
          count += 1
        }).catch(error => {
          console.error('Error al enviar adjunto:', error)
        })
        promesas.push(promesa)
      })
      // eslint-disable-next-line consistent-return
      return Promise.allSettled(promesas)
    },
    guardarAdjunto(afiliadoId, afiliadoAdherenteId, adjuntoCategoriaId, adjunto) {
      return afiliadoAdjuntoAdherenteResource().uploadFileAttachmentAfiliado(afiliadoId, afiliadoAdherenteId, adjuntoCategoriaId, adjunto)
    },
    async generarAdjuntos(adjuntosList, adherenteId) {
      this.overlayConfig.message = 'Documentación'
      this.isSavingAdjuntos = true
      this.progressData.progressAdjuntos = adjuntosList.length
      this.isWorking = true
      const docsToSave = []
      adjuntosList.forEach(doc => {
        const contieneIdGenerado = doc.id.toString().includes('generated')
        if (contieneIdGenerado) {
          const dto = {
            afiliadoId: this.afiliadoDto.id,
            afiliadoAdherenteId: adherenteId,
            adjuntoCategoriaId: doc.afiliadoAdherenteDocumentoCategoria.id,
            adjunto: doc.adjunto,
          }
          docsToSave.push(dto)
        }
      })
      await this.guardarMultiplesAdjuntos(docsToSave).then(() => {
        this.getAfiliadoDocumentos(adherenteId)
      }).catch(error => {
        console.error(error)
      })
      this.overlayConfig.message = ''
      this.progressData.progressAdjuntosDone = 0
      this.progressData.progressAdjuntos = 0
      this.isWorking = false
      this.isSavingAdjuntos = false
    },
    deleteAfiliadoAdherente(item, index) {
      if (item.id.toString().includes('generated')) {
        this.tableDataAfiliadoAdherente.splice(index, 1)
        const itemIndex = this.tableDataAfiliadoAdherenteTemporal.findIndex(element => element.id === item.id)
        if (itemIndex !== -1) {
          this.tableDataAfiliadoAdherenteTemporal.splice(itemIndex, 1)
        }
      } else {
        this.isWorking = true
        afiliadoAdherenteResource().deleteAfiliadoAdherente(item.id)
          .then(() => {
            this.tableDataAfiliadoAdherente.splice(index, 1)
          }).catch(error => {
            console.error(error)
          })
          .finally(() => {
            this.isWorking = false
          })
      }
    },
    getAfiliadoDocumentos(adherenteId) {
      afiliadoAdjuntoAdherenteResource().getListadoAfiliadoDocumento(adherenteId).then(res => {
        this.adjuntosList = res
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {

        })
    },
  },
}
</script>
