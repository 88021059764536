<template>
  <div>
    <h4 v-if="openMode == 'new' || openMode == 'incompleteLoad'">
      {{ title }}
    </h4>
    <b-overlay
      :show="isWorking"
      :rounded="overlayConfig.rounded"
      :variant="overlayConfig.variant"
      :opacity="overlayConfig.opacity"
      :blur="overlayConfig.blur"
    >
      <template #overlay>
        <div v-if="isWorking">
          <div style="text-align: center">
            <span class="spinner-border " />
          </div>
          <div style="text-align: center">
            <small>{{ overlayConfig.message }}</small>
          </div>
        </div>
      </template>
      <validation-observer ref="refDatosContacto">

        <b-row>
          <b-col md="4">
            <b-form-group
              id="frmTelefono"
              label="Teléfono"
            >
              <validation-provider
                name="nombre"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtTelefono"
                    v-model="datosContactoDto.telefono"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                    type="number"
                    maxlength="50"
                    oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    :disabled="!isEditable"
                    @input="restrictToNumbers"
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Nombre obligatorio.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmCorreo"
              label="Correo electrónico"
            >
              <validation-provider
                name="correo"
                rules="required|email|max:250"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtCorreo"
                    v-model="datosContactoDto.email"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                    maxlength="250"
                    :disabled="!isEditable"
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Correo electrónico obligatorio.</small>
                  <small
                    v-if="failedRules.email"
                    class="text-danger"
                  >Formato de correo electrónico inválido.</small>
                  <small
                    v-if="failedRules.max"
                    class="text-danger"
                  >Máximo 250 caracteres.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h5>Domicilio:</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              id="frmCalle"
              label="Calle"
            >
              <validation-provider
                name="calle"
                rules="required|max:250"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtCalle"
                    v-model="datosContactoDto.domicilioCalle"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                    maxlength="250"
                    :disabled="!isEditable"
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Calle obligatoria.</small>
                  <small
                    v-if="failedRules.max"
                    class="text-danger"
                  >Máximo 250 caracteres.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmNumero"
              label="Número"
            >
              <validation-provider
                name="numero"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtDomicilioNumero"
                    v-model="datosContactoDto.domicilioNumero"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                    type="number"
                    maxlength="50"
                    oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    :disabled="!isEditable"
                    @input="restrictToNumbers"
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Número obligatorio.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmOficinaDepto"
              label="Oficina/Depto"
            >
              <validation-provider
                name="oficinaDepto"
                rules="max:250"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtOficinaDepto"
                    v-model="datosContactoDto.domicilioOficinaDepto"
                    maxlength="250"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                    :disabled="!isEditable"
                  />
                  <small
                    v-if="failedRules.max"
                    class="text-danger"
                  >Máximo 250 caracteres.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              id="frmCodigoPostal"
              label="Código Postal"
            >
              <validation-provider
                name="codigoPostal"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtCodigoPostal"
                    v-model="datosContactoDto.codigoPostal"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                    type="number"
                    maxlength="4"
                    :disabled="isBusyBuscandoLocalidad || isBusyBuscandoProvincias || !isEditable"
                    oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    @input="restrictToNumbers"
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Código Postal obligatorio.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmLocalidad"
              label="Localidad"
            >
              <validation-provider
                name="localidad"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">

                  <small v-if="isBusyBuscandoLocalidad">
                    <span
                      v-if="isBusyBuscandoLocalidad"
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    /> Buscando localidades
                  </small>
                  <b-form-select
                    v-if="!isBusyBuscandoLocalidad"
                    id="selectLocalidad"
                    v-model="datosContactoDto.localidad.id"
                    v-b-tooltip.hover="'Seleccione la localidad'"
                    :state="errors.length > 0 ? false:null"
                    :options="optionsLocalidad"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoLocalidad ? 'true' : 'false'"
                    :disabled="isBusyBuscandoProvincias || !isEditable"
                    rounded
                  >
                    <template #first>
                      <b-form-select-option
                        v-if="!isBusyBuscandoLocalidad"
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required && !isBusyBuscandoLocalidad"
                    class="text-danger"
                  >
                    Localidad obligatoria.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmProvincia"
              label="Provincia"
            >
              <validation-provider
                name="provincia"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    v-if="!isBusyBuscandoProvincias"
                    id="txtProvincia"
                    v-model="datosContactoDto.provincia.nombre"
                    :state="errors.length > 0 ? false : null"
                    disabled
                    plaintext
                  />

                  <small v-if="isBusyBuscandoProvincias">

                    <span
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    /> Buscando provincia
                  </small>
                  <small
                    v-if="failedRules.required && !isBusyBuscandoProvincias"
                    class="text-danger"
                  >
                    Provincia obligatoria.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              id="frmNotas"
              label="Notas"
            >
              <b-form-textarea
                id="txtNotas"
                v-model="datosContactoDto.anotaciones"
                autocomplete="off"
                :disabled="!isEditable"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer></b-overlay>
    <hr>
    <b-row>
      <b-col md="4">
        <b-button
          id="btnVolverAfiliados"
          v-b-tooltip.hover
          title="Volver a listado afiliados"
          variant="light"
          squared
          @click="()=>backAfiliado()"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button></b-col>
      <b-col
        md="2"
        class="text-right px-0"
      >
        <b-button
          v-if="isWizard"
          id="btnPasoAnterior"
          v-b-tooltip.hover
          title="Anterior"
          variant="outline-primary"
          squared
          style="primary"
          :disabled="isWorking || isFirstStep"
          @click="anteriorWizard()"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
      </b-col>
      <b-col
        md="2"
        class="text-left  px-0"
      >
        <b-button
          v-if="isWizard"
          id="btnPasoPosterior"
          v-b-tooltip.hover
          squared
          variant="outline-primary"
          title="Siguiente"
          type="submit"
          :disabled="isWorking || !isEditable"
          @click.prevent="posteriorWizard()"
        >
          <span />
          <feather-icon
            v-if="!isWorking"
            icon="ArrowRightIcon"
          />
          <span
            v-if="isWorking"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
      <b-col
        v-if="!isWizard"
        md="4"
        class="text-right"
      >
        <b-button
          v-if="permisosUpd.includes(nameModulo) && isEditable"
          id="btnGuardarDatosContacto"
          v-b-tooltip.hover
          class="text-end"
          variant="primary"
          squared
          title="Guardar datos contacto"
          type="submit"
          :disabled="!isEditable"
          @click.prevent="validarFormulario"
        >
          <font-awesome-icon
            v-if="!isWorking"
            icon="fa-solid fa-floppy-disk"
          />
          <span
            v-if="isWorking"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { localidadResource } from '@/services/localidadResource'
import { afiliadoResource } from '@/services/afiliadoResource'

export default {
  props: {
    afiliadoDto: {
      type: Object,
      required: false,
      default: null,
    },
    idAfiliado: {
      type: String,
      required: false,
      default: null,
    },
    isWizard: {
      type: Boolean,
      required: false,
      default: false,
    },
    openMode: {
      type: String,
      required: true,
    },
    editarEnableDisable: {
      type: Boolean,
      required: false,
    },
    isFirstStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLastStep: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      nameModulo: 'ABM_AFILIADOS',
      title: 'Nuevo Afiliado',
      isWorking: false,
      isSearching: false,
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
      datosContactoOriginalDto: null,
      datosContactoDto: {
        telefono: null,
        email: null,
        domicilioCalle: null,
        domicilioNumero: null,
        domicilioOficinaDepto: null,
        codigoPostal: null,
        localidad: {
          id: null,
          nombre: null,
        },
        provincia: {
          id: null,
          nombre: null,
        },
      },
      optionsLocalidad: [],
      isBusyBuscandoLocalidad: false,
      isBusyBuscandoProvincias: false,
    }
  },
  computed: {
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.openMode === 'incompleteLoad' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        user: 'currentUser',
      },
    ),
  },
  watch: {
    'datosContactoDto.codigoPostal': {
      handler(codigoPostal) {
        this.optionsLocalidad = []
        if (codigoPostal && codigoPostal.length === 4) {
          this.getLocalidadLista(codigoPostal)
        } else {
          this.datosContactoDto.localidad = {
            id: null,
            nombre: null,
          }
        }
      },
    },
    'datosContactoDto.localidad.id': {
      handler(localidadId) {
        if (localidadId) {
          this.getProvinciaPorLocalidad(localidadId)
        } else {
          this.datosContactoDto.provincia.id = null
          this.datosContactoDto.provincia.nombre = null
        }
      },
    },
  },
  created() {
    this.datosContactoOriginalDto = this.deepCopy(this.afiliadoDto)
    this.datosContactoDto = this.deepCopy(this.afiliadoDto)
  },
  methods: {
    backAfiliado() {
      this.$router.push('/afiliados')
    },
    anteriorWizard() {
      this.$emit('previous-step', this.datosContactoDto)
    },
    posteriorWizard() {
      this.validarFormulario()
    },
    validarFormulario() {
      return this.$refs.refDatosContacto.validate().then(success => {
        if (success) {
          if (JSON.stringify(this.datosContactoDto) !== JSON.stringify(this.datosContactoOriginalDto)) {
            this.updateDatosContacto()
          } else if (this.isWizard) {
            this.$emit('next-step', this.datosContactoDto)
          }
        }
      })
    },
    deepCopy(obj) {
      if (obj === null || typeof obj !== 'object') {
        return obj
      }
      if (Array.isArray(obj)) {
        const copy = []
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < obj.length; i++) {
          copy[i] = this.deepCopy(obj[i])
        }
        return copy
      }
      if (typeof obj === 'object') {
        const copy = {}
        // eslint-disable-next-line no-restricted-syntax
        for (const key in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(key)) {
            copy[key] = this.deepCopy(obj[key])
          }
        }
        return copy
      }
      return obj
    },
    updateDatosContacto() {
      this.isWorking = true
      afiliadoResource().updateDatosContacto(this.afiliadoDto.id, this.datosContactoDto)
        .then(resultUpdateDatosContactoDto => {
          this.$emit('guardarDatosContacto', resultUpdateDatosContactoDto)
          if (this.isWizard) {
            this.$emit('next-step', resultUpdateDatosContactoDto)
          }
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isWorking = false
          this.datosContactoOriginalDto = this.deepCopy(this.datosContactoDto)
        })
    },
    restrictToNumbers(event) {
      if (event && event.target) {
        const input = event.target
        if (input.value) {
          input.value = input.value.replace(/\D/g, '') // Remove non-numeric characters
        }
      }
    },
    getLocalidadLista(codigo) {
      this.isBusyBuscandoLocalidad = true
      localidadResource().getByCodigoLocalidad(codigo).then(result => {
        // this.optionsLocalidad = this.optionsLocalidad.concat(result)
        this.optionsLocalidad = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoLocalidad = false
        })
    },
    getProvinciaPorLocalidad(localidadId) {
      this.isBusyBuscandoProvincias = true
      localidadResource().getLocalidadId(localidadId)
        .then(result => {
          this.datosContactoDto.provincia.id = result.id
          this.datosContactoDto.provincia.nombre = result.nombre
        }).catch(error => {
          console.error('error:', error)
        })
        .finally(() => {
          this.isBusyBuscandoProvincias = false
        })
    },
  },
}
</script>
