import axios from 'axios'
import {
  getAfiliadoEmpleador,
  getByAfiliadoId,
} from '@/utils/paths'

const pathGetAfiliadoEmpleador = getAfiliadoEmpleador
const pathGetByAfiliadoId = getByAfiliadoId

export function afiliadoEmpleadorResource() {
  function findAfiliadoEmpleador() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetAfiliadoEmpleador)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findByAfiliadoId(afiliadoId) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByAfiliadoId + afiliadoId)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAfiliadoEmpleador,
    findByAfiliadoId,
  }
}
