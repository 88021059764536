<template>
  <div>
    <h4 v-if="openMode == 'new' || openMode == 'incompleteLoad'">
      {{ title }}
    </h4>
    <b-overlay
      :show="isWorking || isSearching"
      :rounded="overlayConfig.rounded"
      :variant="overlayConfig.variant"
      :opacity="overlayConfig.opacity"
      :blur="overlayConfig.blur"
    >
      <template #overlay>
        <div class="mt-2">
          <div style="text-align: center">
            <span class="spinner-border " />
          </div>
          <div style="text-align: center">
            <small>{{ overlayConfig.message }}</small>
          </div>
        </div>
      </template>

      <validation-observer ref="refDatosLaborales">
        <b-row v-if="isWizard">
          <b-col md="4">
            <b-form-group
              id="frmCuitEmpleador"
              label="C.U.I.T. Empleador"
            >
              <validation-provider
                name="cuit"
                rules="required|min:11"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="block"
                    v-maska="bindedObject"
                    :value="datosLaboralesDto.empleador.cuit"
                    :state="errors.length > 0 ? false:null"
                    data-maska="##-########-#"
                    autocomplete="off"
                    :disabled="!isEditable"
                    placeholder="xx-xxxxxxxx-x"
                    @maska="onMaska"
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >C.U.I.T. obligatorio.
                  </small>
                  <small
                    v-if="failedRules.min"
                    class="text-danger"
                  >C.U.I.T. incompleto.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmRazonSocialEmpleador"
              label="Razón social"
            >
              <validation-provider
                name="razonSocialEmpleador"
                rules="required|max:250|min:2"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtRazonSocialEmpleador"
                    v-model="datosLaboralesDto.empleador.razonSocial"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                    aria-describedby="input-live-help input-live-feedback"
                    maxlength="250"
                    disabled
                  />
                  <b-form-invalid-feedback id="input-live-feedback">
                    Ingrese al menos dos letras
                  </b-form-invalid-feedback>
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Razón social obligatoria.</small>
                  <small
                    v-if="failedRules.max"
                    class="text-danger"
                  >Máximo 250 caracteres.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmNombreFantasiaEmpleador"
              label="Nombre fantasía"
            >
              <validation-provider
                name="nombreFantasiaEmpleador"
                rules="required|max:250"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtNombreFantasiaEmpleador"
                    v-model="datosLaboralesDto.empleador.nombreFantasia"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                    maxlength="250"
                    disabled
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Nombre obligatorio.</small>
                  <small
                    v-if="failedRules.max"
                    class="text-danger"
                  >Máximo 250 caracteres.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isWizard">
          <b-col md="4">
            <b-form-group
              id="frmDomicilioLaboral"
              label="Domicilio laboral"
            >
              <validation-provider
                name="domicilioLaboral"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">

                  <small v-if="isBusyBuscandoDomicilioLaboral">
                    <span
                      v-if="isBusyBuscandoDomicilioLaboral"
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    /> Buscando domicilio laboral
                  </small>
                  <b-form-select
                    v-if="!isBusyBuscandoDomicilioLaboral"
                    id="selectDomicilioLaboral"
                    v-model="datosLaboralesDto.domicilioLaboral.id"
                    v-b-tooltip.hover="'Seleccione el domicilio laboral'"
                    :state="errors.length > 0 ? false:null"
                    :options="optionsDomicilioLaboral"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoDomicilioLaboral ? 'true' : 'false'"
                    :disabled="isBusyBuscandoDomicilioLaboral || !isEditable"
                    rounded
                  >
                    <template #first>
                      <b-form-select-option
                        v-if="!isBusyBuscandoDomicilioLaboral"
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required && !isBusyBuscandoDomicilioLaboral"
                    class="text-danger"
                  >
                    Domicilio laboral obligatorio.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmLegajo"
              label="Legajo"
            >
              <validation-provider name="legajo">
                <div slot-scope="{ }">
                  <b-form-input
                    id="txtLegajo"
                    v-model="datosLaboralesDto.legajo"
                    autocomplete="off"
                    aria-describedby="input-live-help input-live-feedback"
                    maxlength="250"
                    :disabled="!isEditable"
                  />
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmFechaIngreso"
              label="Fecha de ingreso"
            >
              <validation-provider name="FechaIngreso">
                <div slot-scope="{}">
                  <cleave
                    id="date"
                    v-model="datosLaboralesDto.fechaIngreso"
                    class="form-control"
                    :raw="true"
                    :options="options.date"
                    autocomplete="off"
                    placeholder="fecha DD/MM/AAAA"
                    :disabled="!isEditable"
                  />
                  <small
                    v-if="fechaInvalida"
                    class="text-danger"
                  >Debe ser menor o igual a la fecha del día.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isWizard">
          <b-col md="4">
            <b-form-group
              id="frmConvenioTrabajo"
              label="Convenio de trabajo"
            >
              <validation-provider
                name="convenioTrabajo"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">

                  <small v-if="isBusyBuscandoConvenioTrabajo">
                    <span
                      v-if="isBusyBuscandoConvenioTrabajo"
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    /> Buscando convenio de trabajo
                  </small>
                  <b-form-select
                    v-if="!isBusyBuscandoConvenioTrabajo"
                    id="selectConvenioTrabajo"
                    v-model="datosLaboralesDto.convenioTrabajo.id"
                    v-b-tooltip.hover="'Seleccione el tipo de documento'"
                    :state="errors.length > 0 ? false:null"
                    :options="optionsConvenioTrabajo"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoConvenioTrabajo ? 'true' : 'false'"
                    :disabled="isBusyBuscandoConvenioTrabajo || !isEditable"
                    rounded
                    @change="convevionChanged"
                  >
                    <template #first>
                      <b-form-select-option
                        v-if="!isBusyBuscandoConvenioTrabajo"
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required && !isBusyBuscandoConvenioTrabajo"
                    class="text-danger"
                  >
                    Convenio de trabajo obligatorio.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmCategoriaLaboral"
              label="Categoría laboral"
            >
              <validation-provider
                name="categoriaLaboral"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">

                  <small v-if="isBusyBuscandoCategoriaLaboral">
                    <span
                      v-if="isBusyBuscandoCategoriaLaboral"
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    /> Buscando categoría laboral
                  </small>
                  <b-form-select
                    v-if="!isBusyBuscandoCategoriaLaboral"
                    id="selectCategoriaLaboral"
                    v-model="datosLaboralesDto.categoriaLaboral.id"
                    v-b-tooltip.hover="'Seleccione la categoría laboral'"
                    :state="errors.length > 0 ? false:null"
                    :options="optionsCategoriaLaboral"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoCategoriaLaboral ? 'true' : 'false'"
                    :disabled="isBusyBuscandoCategoriaLaboral || !isEditable"
                    rounded
                  >
                    <template #first>
                      <b-form-select-option
                        v-if="!isBusyBuscandoCategoriaLaboral"
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required && !isBusyBuscandoCategoriaLaboral"
                    class="text-danger"
                  >
                    Categoría laboral obligatoria.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmEstadoLaboral"
              label="Estado laboral"
            >
              <validation-provider
                name="estadoLaboral"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">

                  <small v-if="isBusyBuscandoEstadoLaboral">
                    <span
                      v-if="isBusyBuscandoEstadoLaboral"
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    /> Buscando estado laboral
                  </small>
                  <b-form-select
                    v-if="!isBusyBuscandoEstadoLaboral"
                    id="selectEstadoLaboral"
                    v-model="datosLaboralesDto.estadoLaboral.id"
                    v-b-tooltip.hover="'Seleccione el estado laboral'"
                    :state="errors.length > 0 ? false:null"
                    :options="optionsEstadoLaboral"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoEstadoLaboral ? 'true' : 'false'"
                    :disabled="isBusyBuscandoEstadoLaboral || !isEditable"
                    rounded
                  >
                    <template #first>
                      <b-form-select-option
                        v-if="!isBusyBuscandoEstadoLaboral"
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required && !isBusyBuscandoEstadoLaboral"
                    class="text-danger"
                  >
                    Estado laboral obligatorio.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="!isWizard">
          <b-col md="12">
            <b-table
              id="tblAfiliadoEmpleador"
              ref="refTblAfiliadoEmpleador"
              :items="tableDataAfiliadoEmpleador"
              :busy="isWorking"
              :fields="fieldsAfiliadoEmpleador"
              outlined
              show-empty
              small
              hover
              sticky-header="70vh"
              :no-border-collapse="true"
              empty-text="No existe empleador cargado"
              @row-dblclicked="dobleClik"
            >
              <template v-slot:table-busy>
                <div class="text-center">
                  <b-spinner />
                </div>
              </template>
              <template #cell(actions)="row">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <b-button
                    v-if="usarDetalleIntegrado"
                    v-b-tooltip.hover.righttop="'Ver detalle'"
                    size="sm"
                    @click="row.toggleDetails"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    v-if="permisosUpd.includes(nameModulo) && isEditable"
                    v-b-tooltip.hover.righttop="'Editar afiliado empleador'"
                    size="sm"
                    :disabled="!isEditable"
                    @click="editDetalleAfiliadoEmpleador(row.item, row.index, $event.target)"
                  >
                    <font-awesome-icon icon="fa-regular fa-pen-to-square" />
                  </b-button>
                </div>
              </template>
              <template #row-details="row">
                <detalle-afiliado-empleador
                  :item-index="row.index"
                  :detalle-afiliado-empleador="row.item"
                  @changeItemIndex="changeItemIndex"
                />
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row v-if="!isWizard">
          <b-col
            v-if="permisosUpd.includes(nameModulo)"
            md="12"
            class="text-right"
          >
            <b-button
              variant="primary"
              :disabled="!isEditable"
              @click="openModalAddAfiliadoEmpleador"
            >
              Agregar otro Empleador
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
    <div v-if="showAfiliadoEmpleadorModal">
      <afiliado-empleador-modal
        :show-afiliado-empleador-modal="showAfiliadoEmpleadorModal"
        :open-mode-modal="openModeModal"
        :modal-data-afiliado-empleador="modalDataAfiliadoEmpleador"
        :id-afiliado="idAfiliado"
        @agregarAfiliadoEmpleador="agregarAfiliadoEmpleador"
        @editarAfiliadoEmpleador="editarAfiliadoEmpleador"
        @setAfiliadoEmpleador="setAfiliadoEmpleador"
        @closeModalAfiliadoEmpleador="closeModalAfiliadoEmpleador"
      />
    </div>
    <hr>
    <b-row>
      <b-col md="4">
        <b-button
          id="btnVolverAfiliado"
          v-b-tooltip.hover
          title="Volver a listado afiliados"
          variant="light"
          squared
          @click="()=>backAfiliado()"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
      </b-col>
      <b-col
        md="2"
        class="text-right px-0"
      >
        <b-button
          v-if="isWizard"
          id="btnPasoAnterior"
          v-b-tooltip.hover
          title="Anterior"
          variant="outline-primary"
          squared
          style="primary"
          :disabled="isWorking || isFirstStep"
          @click="anteriorWizard()"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
      </b-col>
      <b-col
        md="2"
        class="text-left  px-0"
      >
        <b-button
          v-if="isWizard"
          id="btnPasoPosterior"
          v-b-tooltip.hover
          squared
          variant="outline-primary"
          title="Siguiente"
          type="submit"
          :disabled="(isWorking || isSearching) || !isEditable"
          @click.prevent="posteriorWizard()"
        >
          <span />
          <feather-icon
            v-if="!isWorking"
            icon="ArrowRightIcon"
          />
          <span
            v-if="isWorking"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
      <b-col
        v-if="!isWizard"
        md="4"
        class="text-right"
      >
        <b-button
          v-if="permisosUpd.includes(nameModulo) && isEditable"
          id="btnGuardarDatosLaborales"
          v-b-tooltip.hover
          class="text-end"
          variant="primary"
          squared
          title="Guardar datos laborales"
          type="submit"
          :disabled="!isEditable"
          @click.prevent="validarModal"
        >
          <font-awesome-icon
            v-if="!isWorking"
            icon="fa-solid fa-floppy-disk"
          />
          <span
            v-if="isWorking"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Cleave from 'vue-cleave-component'
import { domicilioLaboralResource } from '@/services/domicilioLaboralResource'
import { convenioTrabajoResource } from '@/services/convenioTrabajoResource'
import { categoriaLaboralResource } from '@/services/categoriaLaboralResource'
import { estadoLaboralResource } from '@/services/estadoLaboralResource'
import { empleadoresResource } from '@/services/empleadoresResource'
import { afiliadoResource } from '@/services/afiliadoResource'
import AfiliadoEmpleadorModal from './AfiliadoEmpleadorModal.vue'
import DetalleAfiliadoEmpleador from './DetalleAfiliadoEmpleador.vue'

export default {
  components: {
    Cleave,
    AfiliadoEmpleadorModal,
    DetalleAfiliadoEmpleador,
  },
  props: {
    afiliadoDto: {
      type: Object,
      required: false,
      default: null,
    },
    isWizard: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFirstStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    openMode: {
      type: String,
      required: true,
    },
    editarEnableDisable: {
      type: Boolean,
      required: false,
    },
    isLastStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    idAfiliado: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      title: 'Nuevo Afiliado',
      nameModulo: 'ABM_AFILIADOS',
      isWorking: false,
      isSearching: false,
      openModeModal: null,
      datosLaboralesOriginalDto: null,
      datosLaboralesDto: {
        id: null,
        empleador: {
          id: null,
          razonSocial: null,
          nombreFantasia: null,
          cuit: null,
        },

        domicilioLaboral: {
          id: null,
          nombre: null,
        },
        legajo: null,
        fechaIngreso: null,
        convenioTrabajo: {
          id: null,
          nombre: null,
        },
        categoriaLaboral: {
          id: null,
          nombre: null,
        },
        estadoLaboral: {
          id: null,
          nombre: null,
        },
      },
      tableDataAfiliadoEmpleadorTemporal: [],
      tableDataAfiliadoEmpleador: null,
      fieldsAfiliadoEmpleador: [
        {
          key: 'empleador.cuit', label: 'C.U.I.T.',
        },
        {
          key: 'empleador.razonSocial', label: 'Razón Social',
        },
        {
          key: 'empleador.nombreFantasia', label: 'Nombre de fantasía',
        },
        { key: 'actions', label: 'Acciones', thStyle: { width: '10%' } },
      ],
      showAfiliadoEmpleadorModal: false,
      usarDetalleIntegrado: true,
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
      optionsDomicilioLaboral: [],
      optionsConvenioTrabajo: [],
      optionsCategoriaLaboral: [],
      optionsEstadoLaboral: [],
      fechaInvalida: false,
      isBusyBuscandoDomicilioLaboral: false,
      isBusyBuscandoConvenioTrabajo: false,
      isBusyBuscandoCategoriaLaboral: false,
      isBusyBuscandoEstadoLaboral: false,
      isBusyBuscandoEmpleador: false,
      options: {
        block: {
          blocks: [2, 8, 1],
          uppercase: true,
        },
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        customDelimiter: {
          delimiters: ['-', '-'],
          blocks: [2, 8, 1],
          uppercase: true,
          numericOnly: true,
        },
      },
      bindedObject: {
        masked: '',
        unmasked: '',
        completed: false,
      },
    }
  },
  computed: {
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.openMode === 'incompleteLoad' || this.editarEnableDisable)
      // return true
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        user: 'currentUser',
      },
    ),
  },
  watch: {
    'datosLaboralesDto.empleador.cuit': {
      handler(newCuit, oldCuit) {
        if (oldCuit !== null) {
          if (newCuit && newCuit.length === 11) {
            if (this.datosLaboralesOriginalDto.cuit !== null && this.datosLaboralesOriginalDto.cuit !== newCuit) {
              this.getEmpleadorByCuit(newCuit)
            }
          } else {
            this.datosLaboralesDto.empleador.razonSocial = null
            this.datosLaboralesDto.empleador.nombreFantasia = null
            this.datosLaboralesDto.domicilioLaboral.id = null
            this.optionsDomicilioLaboral = []
          }
        }
      },

    },
    'datosLaboralesDto.fechaIngreso': {
      handler(newVal) {
        if (newVal && newVal.length === 8) {
          this.validarFechaIngreso()
        }
      },
    },
  },
  created() {
    if (this.afiliadoDto.afiliadoEmpleador.length > 0) {
      if (this.isWizard) {
        this.datosLaboralesDto = { ...this.afiliadoDto.afiliadoEmpleador[0] }
        this.getDomicilioLaboralLista(this.datosLaboralesDto.empleador.id)
      } else {
        this.tableDataAfiliadoEmpleador = Object.values(this.afiliadoDto.afiliadoEmpleador)
      }
      if (this.datosLaboralesDto.convenioTrabajo.id !== null) {
        this.getCategoriaLaboralLista(this.datosLaboralesDto.convenioTrabajo.id)
        this.getEstadoLaboralLista(this.datosLaboralesDto.convenioTrabajo.id)
      }
    }
    this.getConvenioTrabajoLista()
    this.datosLaboralesOriginalDto = { ...this.datosLaboralesDto }
  },
  methods: {
    convevionChanged(convenioId) {
      this.datosLaboralesDto.estadoLaboral.id = null
      this.datosLaboralesDto.categoriaLaboral.id = null
      this.getEstadoLaboralLista(convenioId)
      this.getCategoriaLaboralLista(convenioId)
    },
    onMaska(val) {
      this.datosLaboralesDto.empleador.cuit = val.detail.unmasked
    },
    backAfiliado() {
      this.$router.push('/afiliados')
    },
    anteriorWizard() {
      this.$emit('previous-step', this.afiliadoDto)
    },
    posteriorWizard() {
      this.validarFormulario()
    },
    validarFechaIngreso() {
      const fechaIngresada = new Date(`${this.datosLaboralesDto.fechaIngreso.substr(2, 2)}/${this.datosLaboralesDto.fechaIngreso.substr(0, 2)}/${this.datosLaboralesDto.fechaIngreso.substr(4, 4)}`)
      const fechaActual = new Date().setHours(23, 59, 59, 999)
      this.fechaInvalida = fechaIngresada > fechaActual
    },
    validarFormulario() {
      return this.$refs.refDatosLaborales.validate().then(success => {
        if (success) {
          if (!this.fechaInvalida) {
            if (this.datosLaboralesDto.id !== null && this.datosLaboralesDto.id !== undefined) {
              // update
              this.updateDatosLaborales()
            } else {
              // insert
              this.saveDatosLaborales()
            }
          }
        }
      })
    },
    saveDatosLaborales() {
      this.isWorking = true
      const afiliadoempleador = { ...this.datosLaboralesDto }
      afiliadoempleador.fechaIngreso = this.formatDateToJavaT(afiliadoempleador.fechaIngreso, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
      afiliadoResource().saveDatosLaborales(this.afiliadoDto.id, afiliadoempleador).then(resAfiliadoempleador => {
        this.datosLaboralesDto.id = resAfiliadoempleador.id
        this.datosLaboralesDto.fechaAlta = resAfiliadoempleador.fechaAlta
        this.$emit('next-step', this.datosLaboralesDto)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    updateDatosLaborales() {
      this.isWorking = true
      const afiliadoempleador = { ...this.datosLaboralesDto }
      afiliadoempleador.fechaIngreso = this.formatDateToJavaT(afiliadoempleador.fechaIngreso, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
      afiliadoResource().updateDatosLaborales(this.afiliadoDto.id, afiliadoempleador).then(resAfiliadoempleador => {
        this.datosLaboralesDto.id = resAfiliadoempleador.id
        this.datosLaboralesDto.fechaAlta = resAfiliadoempleador.fechaAlta
        this.$emit('next-step', this.datosLaboralesDto)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    validarModal() {
      this.tableDataAfiliadoEmpleadorTemporal.forEach(element => {
        const str = element
        if (str.id.toString().includes('generated')) {
          str.id = null
        }
      })
      const empleadorSave = this.tableDataAfiliadoEmpleadorTemporal.filter(element => element.id === null)
      const empleadorUpdate = this.tableDataAfiliadoEmpleadorTemporal.filter(element => element.id !== null)
      if (empleadorSave.length > 0) {
        this.saveAllDatosLaborales(empleadorSave)
      }
      if (empleadorUpdate.length > 0) {
        this.updateAllDatosLaborales(empleadorUpdate)
      }
    },
    getEmpleadorByCuit(cuit) {
      this.isBusyBuscandoEmpleador = true
      empleadoresResource().findEmpleadorByCuit(cuit)
        .then(resultEmpleador => {
          if (resultEmpleador.razonSocial !== null && resultEmpleador.nombreFantasia && resultEmpleador.empleadorEstado.nombre === 'Activo') {
            this.datosLaboralesDto.empleador.razonSocial = resultEmpleador.razonSocial
            this.datosLaboralesDto.empleador.id = resultEmpleador.id
            this.datosLaboralesDto.empleador.nombreFantasia = resultEmpleador.nombreFantasia
            // this.getDomicilioLaboralLista(resultEmpleador.id)
            if (resultEmpleador.domiciliosLaborales.length > 0) {
              // calle + número + oficina dpto + cp + localidad
              resultEmpleador.domiciliosLaborales.forEach(domicilio => {
                let domi = null
                if (domicilio.domicilioCalle !== 'VIRTUAL') {
                  domi = {
                    id: domicilio.id,
                    nombre: `${domicilio.domicilioCalle}  ${domicilio.domicilioNumero} ${domicilio.domicilioOficinaDepto ? ` ${domicilio.domicilioOficinaDepto}` : ''} - ${domicilio.codigoPostal} - ${domicilio.localidad.nombre} - ${domicilio.provincia.nombre}`,
                  }
                } else {
                  domi = {
                    id: domicilio.id,
                    nombre: `${domicilio.domicilioCalle} - ${domicilio.delegacion.nombre}`,
                  }
                }
                this.optionsDomicilioLaboral.push(domi)
              })
            }
          } else {
            this.datosLaboralesDto.empleador.cuit = null
          }
        })
        .catch(error => {
          console.error('error:', error)
        })
        .finally(() => {
          this.isBusyBuscandoEmpleador = false
        })
    },
    getDomicilioLaboralLista(empleadorId) {
      this.isBusyBuscandoDomicilioLaboral = true
      domicilioLaboralResource().findByEmpleadorId(empleadorId)
        .then(resultDomicilioLaboral => {
          resultDomicilioLaboral.forEach(domicilio => {
            let domi = null
            if (domicilio.domicilioCalle !== 'VIRTUAL') {
              domi = {
                id: domicilio.id,
                nombre: `${domicilio.domicilioCalle}  ${domicilio.domicilioNumero} ${domicilio.domicilioOficinaDepto ? ` ${domicilio.domicilioOficinaDepto}` : ''} - ${domicilio.codigoPostal} - ${domicilio.localidad.nombre} - ${domicilio.provincia.nombre}`,
              }
            } else {
              domi = {
                id: domicilio.id,
                nombre: `${domicilio.domicilioCalle} - ${domicilio.delegacion.nombre}`,
              }
            }
            this.optionsDomicilioLaboral.push(domi)
          })
        })
        .catch(error => {
          console.error('error:', error)
        })
        .finally(() => {
          this.isBusyBuscandoDomicilioLaboral = false
        })
    },
    // eslint-disable-next-line no-unused-vars
    dobleClik(record, index, mouseEvent) {
      this.tableDataAfiliadoEmpleador.forEach(item => {
        const objIndex = this.tableDataAfiliadoEmpleador.findIndex((obj => obj.id === item.id))
        if (objIndex !== index) {
          // eslint-disable-next-line no-underscore-dangle, no-param-reassign
          item._showDetails = false
        }
      })
      // eslint-disable-next-line no-underscore-dangle
      this.tableDataAfiliadoEmpleador[index]._showDetails = !this.tableDataAfiliadoEmpleador[index]._showDetails
    },
    changeItemIndex({ afiliadoEmpleador }) {
      const objIndex = this.tableDataAfiliadoEmpleador.findIndex((obj => obj.id === afiliadoEmpleador.id))
      if (objIndex !== -1) {
        this.tableDataAfiliadoEmpleador[objIndex] = { ...afiliadoEmpleador }
      } else {
        console.error('Objeto ,objIndex, no encontrado', objIndex)
      }
    },
    agregarAfiliadoEmpleador(afiliadoEmpleador) {
      this.showAfiliadoEmpleadorModal = false
      this.tableDataAfiliadoEmpleador.push(afiliadoEmpleador)
      this.tableDataAfiliadoEmpleadorTemporal.push(afiliadoEmpleador)
    },
    editarAfiliadoEmpleador(afiliadoEmpleador) {
      this.showAfiliadoEmpleadorModal = false
      this.tableDataAfiliadoEmpleadorTemporal.push(afiliadoEmpleador)
    },
    saveAllDatosLaborales(afiliadoEmpleador) {
      this.isWorking = true
      afiliadoEmpleador.forEach(empleador => {
        // eslint-disable-next-line no-param-reassign
        empleador.fechaIngreso = this.formatDateToJavaT(empleador.fechaIngreso, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
        // eslint-disable-next-line no-param-reassign
        empleador.afiliadoId = this.afiliadoDto.id
      })
      afiliadoResource().saveAllDatosLaborales(afiliadoEmpleador)
        .then(() => {
          this.tableDataAfiliadoEmpleadorTemporal = []
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isWorking = false
        })
    },
    updateAllDatosLaborales(afiliadoEmpleador) {
      this.isWorking = true
      afiliadoEmpleador.forEach(empleador => {
        // eslint-disable-next-line no-param-reassign
        empleador.fechaIngreso = this.formatDateToJavaT(empleador.fechaIngreso, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
        // eslint-disable-next-line no-param-reassign
        empleador.afiliadoId = this.afiliadoDto.id
      })
      afiliadoResource().updateAllDatosLaborales(afiliadoEmpleador)
        .then(() => {
          this.tableDataAfiliadoEmpleadorTemporal = []
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isWorking = false
        })
    },
    closeModalAfiliadoEmpleador() {
      this.showAfiliadoEmpleadorModal = false
    },
    openModalAddAfiliadoEmpleador() {
      this.openModeModal = 'new'
      this.showAfiliadoEmpleadorModal = true
      this.modalDataAfiliadoEmpleador = null
    },
    editDetalleAfiliadoEmpleador(data) {
      this.openModeModal = 'edit'
      this.showAfiliadoEmpleadorModal = true
      this.modalDataAfiliadoEmpleador = data
    },
    setAfiliadoEmpleador(data) {
      this.showAfiliadoEmpleadorModal = false
      const pos = this.tableDataAfiliadoEmpleador.findIndex(afiEmp => afiEmp.id === data.id)
      this.tableDataAfiliadoEmpleador[pos] = { ...data }
      this.tableDataAfiliadoEmpleadorTemporal.push(data)
      this.$refs.refTblAfiliadoEmpleador.refresh()
    },
    getConvenioTrabajoLista() {
      this.isBusyBuscandoConvenioLaboral = true
      convenioTrabajoResource().findAllByIsActivoConvenioLaboral().then(result => {
        this.optionsConvenioTrabajo = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoConvenioLaboral = false
        })
    },
    getCategoriaLaboralLista(convenioId) {
      this.isBusyBuscandoCategoriaLaboral = true
      categoriaLaboralResource().findCategoriaLaboralByConvenio(convenioId).then(result => {
        this.optionsCategoriaLaboral = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoCategoriaLaboral = false
        })
    },
    getEstadoLaboralLista(convenioId) {
      this.isBusyBuscandoEstadoLaboral = true
      estadoLaboralResource().findEstadoLaboralByConvenio(convenioId).then(result => {
        this.optionsEstadoLaboral = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEstadoLaboral = false
        })
    },
  },
}
</script>
