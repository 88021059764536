import axios from 'axios'
import {
  getByDocumentosPorReglas,
  getByReglasPorCategoria,
  postReglaAdherente,
  putReglaAdherente,
  deleteReglaAdherente,
} from '@/utils/paths'

const pathGetByDocumentosPorReglas = getByDocumentosPorReglas
const pathGetByReglasPorCategoria = getByReglasPorCategoria
const pathPostReglaAdherente = postReglaAdherente
const pathPutReglaAdherente = putReglaAdherente
const pathDeleteReglaAdherente = deleteReglaAdherente

export function afiliadoAdherenteDocumentoReglasResource() {
  function findByDocumentosPorReglas() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByDocumentosPorReglas)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findByReglasPorCategoria(categoriaId) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByReglasPorCategoria + categoriaId)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveReglaAdherente(reglaAdherente) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostReglaAdherente, reglaAdherente)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateReglaAdherente(id, reglaAdherente) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutReglaAdherente + id, reglaAdherente)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function deleteReglaAdherenteCategoria(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(pathDeleteReglaAdherente + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findByDocumentosPorReglas,
    findByReglasPorCategoria,
    saveReglaAdherente,
    updateReglaAdherente,
    deleteReglaAdherenteCategoria,
  }
}
