<template>
  <div>
    <validation-observer ref="refDocumentacion">
      <h4 v-if="openMode == 'new'|| openMode == 'incompleteLoad'">
        {{ title }}
      </h4>
      <b-overlay
        :show="isWorking"
        :variant="overlayConfig.variant"
        :opacity="overlayConfig.opacity"
        :blur="overlayConfig.blur"
      >
        <template #overlay>
          <div v-if="isWorking">
            <div style="text-align: center">
              <span class="spinner-border p-5" />
            </div>
            <div style="text-align: center">
              <h4>{{ overlayConfig.message }}</h4>
            </div>
            <hr>
            <b-progress
              v-if="progressData.progressAdjuntos > 0 && isSavingAdjuntos"
              height="1rem"
              width="450px"
              :max="progressData.progressAdjuntos"
            >
              <b-progress-bar :value="progressData.progressAdjuntosDone">
                <span>Adjuntando {{ progressData.progressAdjuntosDone }} / {{ progressData.progressAdjuntos }}</span>
              </b-progress-bar>
            </b-progress>
          </div>
        </template>
        <b-row
          v-if="openMode != 'view'"
          class="p-0 "
        >
          <b-col
            md="12"
            class="p-0"
          >
            <b-card-actions
              class="p-0"
              title="Documentación requerida"
              action-collapse
            >
              <b-form-textarea
                id="textAreaDocRequisitos"
                :value="docRequisitos"
                no-auto-shrink
                placeholder="Requisitos"
                disabled
                rows="6"
              />
            </b-card-actions>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="permisosUpd.includes(nameModulo)"
            md="12"
            class="text-right"
          >
            <b-button
              variant="primary"
              :disabled="!isEditable"
              @click="openModalAddDocumentacion"
            >
              Agregar documentación
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-table
              id="tblProductos"
              ref="refTblProductos"
              :items="adjuntosList"
              :fields="fieldsDocumentos"
              show-empty
              small
              outlined
              sticky-header="70vh"
              :no-provider-sorting="false"
              :no-border-collapse="true"
              empty-text="No se encontraron resultados para esta búsqueda"
            >
              <template v-slot:table-busy>
                <div class="text-center">
                  <b-spinner />
                </div>
              </template>
              <template #cell(categoria)="row">
                {{ row.item.afiliadoDocumentoCategoria.nombre }}
              </template>
              <template #cell(archivo)="row">
                {{ row.item.nombre }}
              </template>
              <template #cell(actions)="row">
                <b-button
                  v-b-tooltip.hover.right
                  variant="danger"
                  size="sm"
                  title="Eliminar adjunto"
                  :disabled="puedeSerBorrado()"
                  @click="quitarDoc(row.item, row.index)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
                <!--title="Descargar adjunto"-->
                <b-button
                  v-if="puedeDescargarse(row.item)"
                  v-b-tooltip.hover
                  variant="info"
                  size="sm"
                  :disabled="row.item.isDownloading"
                  @click="descargarAdjunto(row.item)"
                >
                  <font-awesome-icon
                    v-if="!row.item.isDownloading"
                    icon="fa-solid fa-file-arrow-down"
                  />
                  <span
                    v-if="row.item.isDownloading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <hr>
        <b-row>
          <b-col md="4">
            <b-button
              id="btnVolverAfiliados"
              v-b-tooltip.hover
              title="Volver a listado afiliados"
              variant="light"
              squared
              @click="()=>backAfiliados()"
            >
              <feather-icon icon="ArrowLeftIcon" />
            </b-button>
          </b-col>
          <b-col
            md="2"
            class="text-right px-0"
          >
            <b-button
              v-if="permisosUpd.includes(nameModulo) && isWizard"
              id="btnPasoAnterior"
              v-b-tooltip.hover
              title="Anterior"
              variant="outline-primary"
              squared
              style="primary"
              :disabled="isWorking || isFirstStep"
              @click="anteriorWizard()"
            >
              <feather-icon icon="ArrowLeftIcon" />
            </b-button>
          </b-col>
          <b-col
            md="2"
            class="text-left  px-0"
          >
            <b-button
              v-if="!isLastStep && isWizard"
              id="btnPasoPosterior"
              v-b-tooltip.hover
              squared
              variant="outline-primary"
              title="Siguiente"
              type="submit"
              :disabled="isWorking || !isEditable"
              @click.prevent="posteriorWizard()"
            >
              <span />
              <feather-icon
                v-if="!isWorking"
                icon="ArrowRightIcon"
              />
              <span
                v-if="isWorking"
                class="spinner-border spinner-border-sm"
                aria-hidden="true"
              />
            </b-button>
            <b-button
              v-if="isLastStep && isWizard"
              id="btnPasoPosterior"
              v-b-tooltip.hover
              squared
              variant="outline-primary"
              title="Terminar carga"
              type="submit"
              :disabled="isWorking || !isEditable"
              @click.prevent="posteriorWizard()"
            >
              <span>Finalizar</span>

              <span
                v-if="isWorking"
                class="spinner-border spinner-border-sm"
                aria-hidden="true"
              />
            </b-button>
          </b-col>
          <b-col
            v-if="!isWizard"
            md="4"
            class="text-right"
          >
            <b-button
              v-if="permisosUpd.includes(nameModulo) && isEditable"
              id="btnGuardarDocumentacionAfiliado"
              v-b-tooltip.hover
              class="text-end"
              variant="primary"
              squared
              title="Guardar documentación"
              type="submit"
              :disabled="!isEditable"
              @click.prevent="validarFormulario"
            >
              <font-awesome-icon
                v-if="!isWorking"
                icon="fa-solid fa-floppy-disk"
              />
              <span
                v-if="isWorking"
                class="spinner-border spinner-border-sm"
                aria-hidden="true"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </validation-observer>

    <div v-if="showDocumentoCategoriaModal">
      <documentacion-modal
        :show-documento-categoria-modal="showDocumentoCategoriaModal"
        :table-data-documento-categoria="afiliadoDocumentoCategoria"
        @agregarDocumentacion="agregarDocumentacion"
        @closeModalDocumentacion="closeModalDocumentacion"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { documentoResource } from '@/services/documentoResource'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { fileManagement } from '@/utils/fileManagement'
import { documentoCategoriaResource } from '@/services/documentoCategoriaResource'
import { afiliadoCatSindicalDocumentoResource } from '@/services/afiliadoCatSindicalDocumentoResource'
import { afiliadoResource } from '@/services/afiliadoResource'
import DocumentacionModal from './DocumentacionModal.vue'

export default {
  components: {
    DocumentacionModal,
    BCardActions,
  },
  props: {
    afiliadoDto: {
      type: Object,
      required: false,
      default: null,
    },
    idAfiliado: {
      type: String,
      required: false,
      default: null,
    },
    isWizard: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFirstStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    openMode: {
      type: String,
      required: true,
    },
    editarEnableDisable: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    isLastStep: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      title: 'Nuevo Afiliado',
      nameModulo: 'ABM_AFILIADOS',
      seleccionado: null,
      isSavingAdjuntos: false,
      isWorking: false,
      showModalUploadFile: false,
      showDocumentoCategoriaModal: false,
      adjuntosList: [],
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '1',
        blur: '5px',
        message: '',
      },
      documentacionAfiliado: [],
      documentacionAfiliadoDb: [],
      documentacionAfiliadoDbIds: [],
      isBusyBuscando: false,
      afiliadoDocumentoCategoriaLista: [],
      fieldsDocumentos: [
        { key: 'categoria', label: 'categoria' },
        { key: 'archivo', label: 'archivo' },
        { key: 'actions', label: 'acciones' },
      ],
      docuDto: {
        id: null,
        pathFile: null,
        nombre: null,
        tipo: null,
        extension: null,
        size: null,
        afiliadoDocumentoCategoria: {
          id: null,
          nombre: null,
          descripcion: null,
          obligatorio: null,
          habilitado: null,
        },
      },
      progressData: {
        progressAdjuntos: 0,
        progressAdjuntosDone: 0,
      },
      afiliadoDocumentoCategoria: [],
      pageTotalElements: 0,
      currentPage: 1,
      pageSize: 0,
      pageDocumento: {
        page: 0,
        size: 7,
      },
    }
  },
  computed: {
    docRequisitos() {
      let list = ''
      this.afiliadoDocumentoCategoria.forEach(doc => {
        if (doc.obligatorio) {
          const docName = `${doc.nombre}\n`
          list = list.concat(docName)
        }
      })
      return list
    },
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.openMode === 'incompleteLoad' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        currentUser: 'getCurrentUser',
      },
    ),
    buttonNextStepTitle() {
      return this.isLastStep ? 'Finalizar carga afiliado' : 'Siguiente'
    },
    buttonNextStepVariant() {
      return this.isLastStep ? 'primary' : 'light'
    },
  },
  watch: {
    'afiliadoDto.categoriaSindical.id': {
      handler(newId, oldId) {
        if (newId !== null && newId !== undefined && newId !== oldId) {
          this.getDocumentosPorCategoriaSindical()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (this.afiliadoDto.id !== null) {
      this.getAfiliadoDocumentos()
    }
  },
  methods: {
    toast(type, title, html) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">${html}</p>`
      this.$swal({
        icon: type,
        title,
        html: htmlText,
        background: this.swalConfig.background,
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
      })
    },
    puedeDescargarse(doc) {
      let puede
      if (doc.id !== null && doc.id !== undefined) {
        const contieneIdGenerado = doc.id.toString().includes('generated')
        puede = doc.id !== null && contieneIdGenerado === false
      }
      return puede
    },
    puedeSerBorrado() {
      return !this.isEditable
    },
    generarRandomId() {
      return `generated${Math.random().toString(36).substring(7)}`
    },
    quitarDoc(item, index) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">¿Desea quitar esta documentación ${item.nombre}? </p>`
      this.$swal({
        title: 'Quitar documentación',
        html: htmlText,
        icon: 'warning',
        background: this.swalConfig.background,
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.adjuntosList.splice(index, 1)
        }
      })
    },
    async getAllCategorias() {
      await documentoCategoriaResource().findAllByIsActivoCategoriaDocumentacion()
        .then(resultFindAllCategorias => {
          this.afiliadoDocumentoCategoriaLista = resultFindAllCategorias
        }).catch(error => {
          console.error(error)
          this.afiliadoDocumentoCategoriaLista = []
        })
        .finally(() => {
          this.isBusyBuscando = false
        })
    },
    backAfiliados() {
      this.$router.push('/afiliados')
    },
    anteriorWizard() {
      this.$emit('previous-step', this.afiliadoDto)
    },
    posteriorWizard() {
      this.validarFormulario()
    },
    async findAfiliado(idAfiliado) {
      this.isWorking = true
      this.overlayConfig.message = 'Recuperando documentación'
      if (idAfiliado !== undefined) {
        await documentoResource().getByEmpleadorIdDocumento(idAfiliado)
          .then(resultsFindByidAfiliado => {
            this.documentacionAfiliadoDb = resultsFindByidAfiliado
          }).catch(error => {
            console.error(error)
          })
          .finally(() => {
            this.isWorking = false
          })
      } else {
        this.isWorking = false
      }
    },
    agregarDocumentacion(docu) {
      this.showDocumentoCategoriaModal = false
      this.adjuntosList.push(docu)
      // this.agregaDoc(afiliadoDocumentoCatetoriaId)
    },
    agregaDoc(afiliadoDocumentoCatetoriaId) {
      const tidoDocCat = this.afiliadoDocumentoCategoriaLista.filter(docCat => docCat.id === afiliadoDocumentoCatetoriaId)
      const docuDto = {
        id: null,
        pathFile: null,
        nombre: null,
        tipo: null,
        extension: null,
        size: null,
        isDownloading: false,
        afiliadoDocumentoCategoria: tidoDocCat[0],
      }
      this.documentacionAfiliado.push(docuDto)
    },
    closeModalDocumentacion() {
      this.showDocumentoCategoriaModal = false
    },
    openModalAddDocumentacion() {
      this.showDocumentoCategoriaModal = true
    },
    validarFormulario() {
      return this.$refs.refDocumentacion.validate().then(success => {
        if (success) {
          if (this.validarRequisitos()) {
            this.generarAdjuntos()
          }
        }
      })
    },
    validarRequisitos() {
      const docsMissing = []

      this.afiliadoDocumentoCategoria.forEach(afiliadoDocuCat => {
        if (afiliadoDocuCat.obligatorio) {
          const docus = this.adjuntosList.filter(docAdjunto => docAdjunto.afiliadoDocumentoCategoria.id === afiliadoDocuCat.id)
          if (docus.length === 0) {
            docsMissing.push(afiliadoDocuCat)
          }
        }
      })
      if (docsMissing.length > 0) {
        this.toast('warning', 'Adjuntos', `Archivos pendientes de adjuntar: ${this.buildStringMessage(docsMissing)}`)
        return false
      }
      return true
    },
    buildStringMessage(detalleErrores) {
      this.customizeSweetAlert2()
      let message = '<ul>'
      detalleErrores.forEach(item => {
        message += `<li style="color: ${this.swalConfig.textColor}"> ${item.nombre} <br>`
        message += '</li>'
      })
      message += '</ul>'
      return message
    },
    async generarAdjuntos() {
      this.overlayConfig.message = 'Documentación'
      this.isSavingAdjuntos = true
      this.progressData.progressAdjuntos = this.adjuntosList.length
      this.isWorking = true
      const docsToSave = []
      const docsToBeRemoved = []

      this.documentacionAfiliadoDbIds.forEach(id => {
        const index = this.adjuntosList.findIndex(item => item.id === id)
        if (index === -1) {
          docsToBeRemoved.push(id)
        }
      })
      if (docsToBeRemoved.length > 0) {
        await this.deleteDocs(docsToBeRemoved)
      }
      this.adjuntosList.forEach(doc => {
        const contieneIdGenerado = doc.id.toString().includes('generated')
        if (contieneIdGenerado) {
          const dto = {
            afiliadoId: this.afiliadoDto.id,
            afiliadoDocumentoCategoriaId: doc.afiliadoDocumentoCategoria.id,
            adjunto: doc.adjunto,
          }
          docsToSave.push(dto)
        }
      })
      await this.guardarMultiplesAdjuntos(docsToSave).then(() => {
        if (this.isWizard) {
          this.finalizarCarga()
        } else {
          this.getAfiliadoDocumentos()
        }
      }).catch(error => {
        console.error(error)
      })
      this.overlayConfig.message = ''
      this.progressData.progressAdjuntosDone = 0
      this.progressData.progressAdjuntos = 0
      this.isWorking = false
      this.isSavingAdjuntos = false
      /*
      if (this.isWizard) {
        this.$emit('next-step', this.documentacionAfiliado)
      } else if (docsToBeRemoved.length > 0 || docsToSave.length > 0) {
        this.iniciar()
      } */
    },
    guardarAdjunto(afiliadoId, afiliadoDocumentoCategoriaId, adjunto) {
      return afiliadoResource().uploadFileAttachmentAfiliado(afiliadoId, afiliadoDocumentoCategoriaId, adjunto)
    },
    async deleteDocs(docsToBeRemoved) {
      const promesas = []
      await docsToBeRemoved.forEach(async idToRemove => {
        const promesa = afiliadoResource().deleteDocumento(idToRemove).then(res => {
          const adjuntoIndex = this.documentacionAfiliadoDbIds.findIndex(id => id === res)
          this.documentacionAfiliadoDbIds.splice(adjuntoIndex, 1)
        }).catch(error => {
          console.error('error al eliminar documento afiliado:', error)
        })
        promesas.push(promesa)
      })
      return Promise.allSettled(promesas)
    },
    guardarMultiplesAdjuntos(documentos) {
      const promesas = []
      let count = 1
      documentos.forEach(async documento => {
        const promesa = this.guardarAdjunto(this.afiliadoDto.id, documento.afiliadoDocumentoCategoriaId, documento.adjunto).then(() => {
          this.progressData.progressAdjuntosDone = count
          count += 1
        }).catch(error => {
          console.error('Error al enviar adjunto:', error)
        })
        promesas.push(promesa)
      })
      // eslint-disable-next-line consistent-return
      return Promise.allSettled(promesas)
    },
    descargarAdjunto(docToDownload) {
      const docIndex = this.adjuntosList.findIndex(doc => doc.id === docToDownload.id)
      this.adjuntosList[docIndex].isDownloading = true
      afiliadoResource().getAdjuntoByte(docToDownload.id).then(resBytes => {
        fileManagement().getAdjunto(resBytes, docToDownload.nombre, docToDownload.tipoAdjunto)
        this.adjuntosList[docIndex].isDownloading = false
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.adjuntosList[docIndex].isDownloading = false
        })
    },

    dobleClik(record) {
      this.viewDetalleDocumento(record)
    },
    buscarDocumentacion() {
      this.currentPage = 1
      this.pageDocumento.page = 0
      this.getDocumentacionByFilter()
    },
    getAfiliadoDocumentos() {
      afiliadoResource().getListadoAfiliadoDocumento(this.afiliadoDto.id).then(res => {
        this.adjuntosList = res
        this.documentacionAfiliadoDbIds = res.map(doc => doc.id)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {

        })
    },
    getDocumentosPorCategoriaSindical() {
      const idCatSindical = this.afiliadoDto.categoriaSindical.id
      this.afiliadoDocumentoCategoria = []
      afiliadoCatSindicalDocumentoResource().getDocumentosPorCategoriaSindical(idCatSindical).then(res => {
        res.forEach(doc => {
          const documento = {
            id: doc.afiliadoDocumentoCategoria.id,
            nombre: doc.afiliadoDocumentoCategoria.nombre,
            obligatorio: doc.obligatorio,
          }
          this.afiliadoDocumentoCategoria.push(documento)
        })
      }).catch(error => {
        console.error('error:', error)
      })
    },
    deleteAdjunto(index) {
      this.adjuntosList.splice(index, 1) // Elimina y obtiene el adjunto eliminado
    },
    finalizarCarga() {
      afiliadoResource().finalizarCarga(this.afiliadoDto.id).then(() => {
        this.$emit('next-step')
      }).catch(error => {
        console.error(error)
      })
    },
    getDocumentacionByFilter() {
      const filtros = {
        afiliadoId: this.afiliadoDto.id,
        pageNumber: this.pageDocumento.page,
        pageSize: this.pageDocumento.size,
        sort: 'nombre',
        order: 'asc',
      }
      documentoResource().postByEmpleadorIdFilter(filtros)
        .then(resultPostByAfiliadoByFilter => {
          this.tableDataDocumento = resultPostByAfiliadoByFilter.content
          this.pageTotalElements = resultPostByAfiliadoByFilter.totalElements
          this.pageNumber = resultPostByAfiliadoByFilter.number
          this.pageSize = resultPostByAfiliadoByFilter.size
        }).catch(error => {
          console.error('errors:', error)
          this.tableDataDocumento = []
        })
        .finally(() => {
          this.isBusyBuscando = false
        })
    },
  },
}
</script>
