import axios from 'axios'
import {
  getAfiliado,
  postAfiliadoByFilterSpecs,
  putAfiliadoDatosContacto,
  getVerificarCuilAfiliado,
  postSaveDatosPersonales,
  putUpdateDatosPersonales,
  getAfiliadoById,
  postSaveDatosLaborales,
  putUpdateDatosLaborales,
  postUploadAfiliadoDocumento,
  getListaAfiliadoDocumento,
  getAdjuntoAfiliadoDocumento,
  postFinalizarCargaAfiliado,
  postAllDatosLaborales,
  putAllDatosLaborales,
  deleteAfiliadoDocumento,
} from '@/utils/paths'

const pathGetAfiliado = getAfiliado
const pathPostAfiliadoByFilter = postAfiliadoByFilterSpecs
const pathPutAfiliadoDatosContacto = putAfiliadoDatosContacto
const pathGetVerificarCuilAfiliado = getVerificarCuilAfiliado
const pathPostSaveDatosPersonales = postSaveDatosPersonales
const pathPutUpdateDatosPersonales = putUpdateDatosPersonales
const pathGetAfiliadoById = getAfiliadoById
const pathPostSaveDatosLaborales = postSaveDatosLaborales
const pathPutUpdateDatosLaborales = putUpdateDatosLaborales
const pathPostUploadAfiliadoDocumento = postUploadAfiliadoDocumento
const pathGetListaAfiliadoDocumento = getListaAfiliadoDocumento
const pathGetAdjuntoAfiliadoDocumento = getAdjuntoAfiliadoDocumento
const pathPostFinalizarCargaAfiliado = postFinalizarCargaAfiliado
const pathPostAllDatosLaborales = postAllDatosLaborales
const pathPutAllDatosLaborales = putAllDatosLaborales
const pathDeleteAfiliadoDocumento = deleteAfiliadoDocumento
export function afiliadoResource() {
  function findAfiliadoDetalle() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetAfiliado)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAfiliadoById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetAfiliadoById + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function postAfiliadoByFilter(filterAfiliadoDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostAfiliadoByFilter, filterAfiliadoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  }
  function updateDatosContacto(idAfiliado, datosContactoDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutAfiliadoDatosContacto + idAfiliado, datosContactoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  }
  function saveDatosPersonales(datosPersonalesDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveDatosPersonales, datosPersonalesDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  }
  function updateDatosPersonales(datosPersonalesDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateDatosPersonales + datosPersonalesDto.id, datosPersonalesDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  }
  function verificarCuilAfiliado(cuil) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetVerificarCuilAfiliado + cuil)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveDatosLaborales(idAfiliado, afiliadoEmpleadorDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${pathPostSaveDatosLaborales + idAfiliado}/datosLaborales`, afiliadoEmpleadorDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  }
  function updateDatosLaborales(idAfiliado, afiliadoEmpleadorDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${pathPutUpdateDatosLaborales + idAfiliado}/datosLaborales`, afiliadoEmpleadorDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  }
  function saveAllDatosLaborales(datosLaboralesDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostAllDatosLaborales, datosLaboralesDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateAllDatosLaborales(datosLaboralesDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutAllDatosLaborales, datosLaboralesDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function uploadFileAttachmentAfiliado(afiliadoId, afiliadoDocumentoCategoriaId, adjunto) {
    return new Promise((resolve, reject) => {
      const formData = new FormData() // eslint-disable-line
      formData.append('adjunto', adjunto)
      axios
        .post(`${pathPostUploadAfiliadoDocumento}/${afiliadoId}/${afiliadoDocumentoCategoriaId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getListadoAfiliadoDocumento(afiliadoId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetListaAfiliadoDocumento + afiliadoId,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getAdjuntoByte(afiliadoDocumentoId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAdjuntoAfiliadoDocumento + afiliadoDocumentoId, { responseType: 'blob' },
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function finalizarCarga(afiliadoId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${pathPostFinalizarCargaAfiliado + afiliadoId}`)
        .then(results => {
          resolve(results.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  }
  function deleteDocumento(afiliadoDocumentoId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(pathDeleteAfiliadoDocumento + afiliadoDocumentoId)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAfiliadoDetalle,
    findAfiliadoById,
    postAfiliadoByFilter,
    updateDatosContacto,
    verificarCuilAfiliado,
    saveDatosPersonales,
    updateDatosPersonales,
    saveDatosLaborales,
    updateDatosLaborales,
    uploadFileAttachmentAfiliado,
    getListadoAfiliadoDocumento,
    getAdjuntoByte,
    finalizarCarga,
    saveAllDatosLaborales,
    updateAllDatosLaborales,
    deleteDocumento,
  }
}
