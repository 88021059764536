import axios from 'axios'
import {
  getMotivoBaja,
  getByIsActivoMotivoBaja,
  postSaveMotivoBajaAfiliado,
  putUpdateMotivoBajaAfiliado,
} from '@/utils/paths'

const pathGetMotivoBaja = getMotivoBaja
const pathGetByIsActivoMotivoBaja = getByIsActivoMotivoBaja
const pathPostSaveMotivoBajaAfiliado = postSaveMotivoBajaAfiliado
const pathPutUpdateMotivoBajaAfiliado = putUpdateMotivoBajaAfiliado

export function motivoBajaResource() {
  function findAllMotivoBaja() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetMotivoBaja)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoMotivoBaja() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoMotivoBaja)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveMotivoBajaAfiliado(motivoBajaAfiliadoDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveMotivoBajaAfiliado, motivoBajaAfiliadoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateMotivoBajaAfiliado(id, motivoBajaAfiliadoDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateMotivoBajaAfiliado + id, motivoBajaAfiliadoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllMotivoBaja,
    findAllByIsActivoMotivoBaja,
    saveMotivoBajaAfiliado,
    updateMotivoBajaAfiliado,
  }
}
