import axios from 'axios'
import {
  getAllGenero,
  getByIsActivoGenero,
  postSaveGenero,
  putUpdateGenero,
} from '@/utils/paths'

const pathGetGenero = getAllGenero
const pathGetByIsActivoGenero = getByIsActivoGenero
const pathPostSaveGenero = postSaveGenero
const pathPutUpdateGenero = putUpdateGenero

export function generoResource() {
  function findAllGenero() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetGenero)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoGenero() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoGenero)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveGenero(generoDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveGenero, generoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateGenero(id, generoDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateGenero + id, generoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllGenero,
    findAllByIsActivoGenero,
    saveGenero,
    updateGenero,
  }
}
