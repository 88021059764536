<template>
  <div :style="`border: 1px inset ${getColorEstado}`">
    <b-card class="m-0">
      <b-row>
        <b-col md="6">
          <b-form-group label="Nombre y apellido">
            <h5>{{ afiliadoAdherenteDto.nombre.concat(' ', afiliadoAdherenteDto.apellido) }}</h5>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="DNI">
            <h5>{{ afiliadoAdherenteDto.numeroDocumento }}</h5>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group label="Fecha Nacimiento">
            <h5>{{ formatDateToJavaT(afiliadoAdherenteDto.fechaNacimiento, 'DDMMYYYY', 'DD/MM/YYYY') }}</h5>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Género">
            <h5>{{ afiliadoAdherenteDto.genero.nombre }}</h5>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group label="Edad">
            <h5>{{ afiliadoAdherenteDto.edad }}</h5>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Parentesco">
            <h5>{{ afiliadoAdherenteDto.relacionFamiliar.nombre }}</h5>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script
>
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
  },
  props: {
    detalleAfiliadoAdherente: {
      type: Object,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {
      afiliadoAdherenteDto: {},
    }
  },
  computed: {
    getColorEstado() {
      const { skin } = useAppConfig()
      let whiteBlack = 'black'
      if (skin.value === 'dark') {
        whiteBlack = 'white'
      }
      const color = whiteBlack

      return color
    },
  },
  created() {
    this.afiliadoAdherenteDto = { ...this.detalleAfiliadoAdherente }
  },
  methods: {
  },
}
</script>
