import axios from 'axios'
import {
  getEstadoCivil,
  getByIsActivoEstadoCivil,
  postSaveEstadoCivil,
  putUpdateEstadoCivil,
} from '@/utils/paths'

const pathGetEstadoCivil = getEstadoCivil
const pathGetByIsActivoEstadoCivil = getByIsActivoEstadoCivil
const pathPostSaveEstadoCivil = postSaveEstadoCivil
const pathPutUpdateEstadoCivil = putUpdateEstadoCivil

export function estadoCivilResource() {
  function findAllEstadoCivil() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetEstadoCivil)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoEstadoCivil() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoEstadoCivil)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveEstadoCivil(estadoCivilDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveEstadoCivil, estadoCivilDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateEstadoCivil(id, estadoCivilDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateEstadoCivil + id, estadoCivilDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllEstadoCivil,
    findAllByIsActivoEstadoCivil,
    saveEstadoCivil,
    updateEstadoCivil,
  }
}
