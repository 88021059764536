<template>
  <div>
    <!--layout="horizontal" step-size="xl"-->
    <b-overlay
      :show="isWorking"
      :rounded="overlayConfig.rounded"
      :variant="overlayConfig.variant"
      :opacity="overlayConfig.opacity"
      :blur="overlayConfig.blur"
    >
      <template #overlay>
        <div class="mt-2">
          <div style="text-align: center">
            <span class="spinner-border " />
          </div>
          <div style="text-align: center">
            <small>{{ overlayConfig.message }}</small>
          </div>
        </div>
      </template>
      <form-wizard
        ref="refFormWizard"
        color="#50566E"
        :title="null"
        :subtitle="null"
        :start-index="0"
        finish-button-text="Finalizar"
        back-button-text="Anterior"
        next-button-text="Siguiente"
        :hide-buttons="true"
        layout="vertical"
        transition="fade"
        class="wizard-vertical mb-3"
        @on-complete="formSubmitted"
      >
        <tab-content
          title="Datos personales"
          icon="feather icon-bookmark"
        >
          <datos-personales
            v-if="!isWorking"
            ref="refDatosPersonales"
            :is-first-step="true"
            :is-wizard="true"
            :is-last-step="false"
            :open-mode="openMode"
            :afiliado-dto="afiliadoDto"
            :id-afiliado="idAfiliado"
            @next-step="formWizardDatosPersonalesNextStep"
            @previous-step="formWizardPrevioustStep"
          />
        </tab-content>
        <tab-content
          title="Datos laborales"
          icon="feather icon-inbox"
        >
          <datos-laborales
            v-if="!isWorking"
            :is-wizard="true"
            :is-first-step="false"
            :is-last-step="false"
            :open-mode="openMode"
            :id-afiliado="idAfiliado"
            :afiliado-dto="afiliadoDto"
            :datos-laborales="datosLaborales"
            @next-step="formWizardDatosLaboralesNextStep"
            @previous-step="formWizardPrevioustStep"
          />
        </tab-content>
        <tab-content
          title="Datos contacto"
          icon="feather icon-inbox"
        >
          <datos-contacto
            v-if="!isWorking"
            :is-wizard="true"
            :is-first-step="false"
            :is-last-step="false"
            :open-mode="openMode"
            :id-afiliado="idAfiliado"
            :afiliado-dto="afiliadoDto"
            :datos-contacto="datosContacto"
            @next-step="formWizardDatosContactoNextStep"
            @previous-step="formWizardPrevioustStep"
          />
        </tab-content>
        <tab-content
          title="Grupo familiar"
          icon="feather icon-home"
        >
          <grupo-familiar
            v-if="!isWorking"
            :is-wizard="true"
            :is-first-step="false"
            :is-last-step="false"
            :open-mode="openMode"
            :id-afiliado="idAfiliado"
            :afiliado-dto="afiliadoDto"
            @next-step="formWizardGrupoFamiliarNextStep"
            @previous-step="formWizardPrevioustStep"
          />
        </tab-content>
        <tab-content
          title="Documentación"
          icon="feather icon-file-text"
        >
          <documentacion-afiliado
            v-if="!isWorking"
            :is-wizard="true"
            :is-first-step="false"
            :is-last-step="true"
            :open-mode="openMode"
            :id-afiliado="idAfiliado"
            :afiliado-dto="afiliadoDto"
            @next-step="formWizardDocumentacionNextStep"
            @previous-step="formWizardPrevioustStep"
          />
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { afiliadoResource } from '@/services/afiliadoResource'
import DatosPersonales from './DatosPersonales.vue'
import DatosLaborales from './DatosLaborales.vue'
import DatosContacto from './DatosContacto.vue'
import GrupoFamiliar from './GrupoFamiliar.vue'
import DocumentacionAfiliado from './DocumentacionAfiliado.vue'

export default {
  childInterface: {
    getAll: () => { },
  },
  components: {
    FormWizard,
    TabContent,
    DatosPersonales,
    DatosLaborales,
    DatosContacto,
    GrupoFamiliar,
    DocumentacionAfiliado,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    idAfiliado: {
      type: String,
      required: false,
      default: undefined,
    },
    openMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      titulo: '',
      tabIndex: null,
      title: '',
      isWorking: false,
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.5',
        blur: '5px',
        message: '',
      },
      datosLaborales: {
        id: null,
        cuil: null,
      },
      datosContacto: {
        id: null,
        telefono: null,
        email: null,
        domicilioCalle: null,
        domicilioNumero: null,
      },
      afiliadoDto: {
        id: null,
        cuil: null,
        documentoTipo: {
          id: null,
          nombre: null,
        },
        numeroDocumento: null,
        apellido: null,
        nombre: null,
        afiliadoEmpleador: [],
        nombreApellidoCustom: null,
        fechaNacimiento: null,
        telefono: null,
        email: null,
        domicilioCalle: null,
        domicilioNumero: null,
        domicilioOficinaDepto: null,
        codigoPostal: null,
        etapaCompletada: null,
        logo: null,
        provincia: {
          id: null,
          nombre: null,
        },
        localidad: {
          id: null,
          nombre: null,
        },
        cargaCompletada: null,
        estadoCivil: {
          id: null,
          nombre: null,
        },
        genero: {
          id: null,
          nombre: null,
        },
        categoriaSindical: {
          id: null,
          nombre: null,
        },
        cbu: null,
        afiliacionEstado: {
          id: null,
          nombre: null,
        },
        fechaAlta: null,
        fechaBaja: null,
        motivoBaja: {
          id: null,
          nombre: null,
        },
        delegacion: {
          id: null,
          nombre: null,
        },
      },
      afiliadoEmpleadorDto: {
        cuitEmpleador: null,
        razonSocial: null,
        nombreFantasia: null,
        domicilioLaboral: null,
        legajo: null,
        fechaIngreso: null,
        convenioTrabajo: {
          id: null,
          nombre: null,
        },
        categoriaLaboral: {
          id: null,
          nombre: null,
        },
        estadoLaboral: {
          id: null,
          nombre: null,
        },
      },
    }
  },
  computed: {
    currentTab() {
      return this.$refs.refFormWizard
    },
    afiliadoAsArray() {
      const arrAfiliado = []
      arrAfiliado.push(this.afiliadoDto)
      return arrAfiliado
    },
  },
  async created() {
    if (this.idAfiliado !== undefined) {
      await this.findAfiliado(this.idAfiliado)
    }
    this.setTabsLogic()
    if (this.afiliadoDto.id) {
      this.titulo = 'Ver Afiliado'
    } else {
      this.titulo = 'Nuevo Afiliado'
    }
  },
  methods: {
    setTabsLogic() {
      if (this.afiliadoDto.etapaGrupoFamiliarCompletada !== null && this.afiliadoDto.etapaGrupoFamiliarCompletada === true) {
        // Entonces vamos al tab documentacion
        this.$refs.refFormWizard.activateTabAndCheckStep(4)
        this.$refs.refFormWizard.navigateToTab(4)
      } else if (this.afiliadoDto.etapaDatosContactoCompletada !== null && this.afiliadoDto.etapaDatosContactoCompletada === true) {
        // Etnonces vamos al tab grupo familiar
        this.$refs.refFormWizard.activateTabAndCheckStep(3)
        this.$refs.refFormWizard.navigateToTab(3)
      } else if (this.afiliadoDto.etapaDatosLaboralesCompletada !== null && this.afiliadoDto.etapaDatosLaboralesCompletada === true) {
        // Entonces vamos al tab Datos contacto
        this.$refs.refFormWizard.activateTabAndCheckStep(2)
        this.$refs.refFormWizard.navigateToTab(2)
      } else if (this.afiliadoDto.etapaDatosPersonalesCompletada !== null && this.afiliadoDto.etapaDatosPersonalesCompletada === true) {
        // this.$refs.refFormWizard.activateTab(0)
        // this.$refs.refFormWizard.activateTab(1)
        this.$refs.refFormWizard.activateTabAndCheckStep(1)
        this.$refs.refFormWizard.navigateToTab(1)
        // this.$refs.refFormWizard.activeTab = 1
      }
    },
    findAfiliado(idAfiliado) {
      this.isWorking = true
      this.overlayConfig.message = 'Recuperando datos de afiliado'
      let promise = null
      if (idAfiliado !== undefined) {
        promise = afiliadoResource().findAfiliadoById(idAfiliado)
          .then(afliadoRes => {
            this.afiliadoDto = { ...afliadoRes }
          }).catch(error => {
            console.error(error)
          })
          .finally(() => {
            this.isWorking = false
          })
      }
      return promise
    },
    formWizardDatosPersonalesNextStep(datosPersonalesDto) {
      this.afiliadoDto.id = datosPersonalesDto.id
      this.afiliadoDto.cuil = datosPersonalesDto.cuil
      this.afiliadoDto.documentoTipo = datosPersonalesDto.documentoTipo
      this.afiliadoDto.numeroDocumento = datosPersonalesDto.numeroDocumento
      this.afiliadoDto.apellido = datosPersonalesDto.apellido
      this.afiliadoDto.nombre = datosPersonalesDto.nombre
      this.afiliadoDto.fechaNacimiento = datosPersonalesDto.fechaNacimiento
      this.afiliadoDto.genero = datosPersonalesDto.genero
      this.afiliadoDto.estadoCivil = datosPersonalesDto.estadoCivil
      this.afiliadoDto.delegacion = datosPersonalesDto.delegacion
      this.afiliadoDto.categoriaSindical = datosPersonalesDto.categoriaSindical
      this.afiliadoDto.cbu = datosPersonalesDto.cbu
      this.afiliadoDto.afiliacionEstado = datosPersonalesDto.afiliacionEstado
      this.afiliadoDto.fechaAlta = datosPersonalesDto.fechaAlta
      // this.$refs.refFormWizard.nextTab()
      this.next()
    },
    formWizardDatosLaboralesNextStep(datosLaboralesDto) {
      this.afiliadoEmpleadorDto.cuitEmpleador = datosLaboralesDto.cuitEmpleador
      this.afiliadoEmpleadorDto.razonSocial = datosLaboralesDto.razonSocial
      this.afiliadoEmpleadorDto.nombreFantasia = datosLaboralesDto.nombreFantasia
      this.afiliadoEmpleadorDto.domicilioLaboral = datosLaboralesDto.domicilioLaboral
      this.afiliadoEmpleadorDto.legajo = datosLaboralesDto.legajo
      this.afiliadoEmpleadorDto.fechaIngreso = datosLaboralesDto.fechaIngreso
      this.afiliadoEmpleadorDto.convenioTrabajo = datosLaboralesDto.convenioTrabajo
      this.afiliadoEmpleadorDto.categoriaLaboral = datosLaboralesDto.categoriaLaboral
      this.afiliadoEmpleadorDto.estadoLaboral = datosLaboralesDto.estadoLaboral
      // this.$refs.refFormWizard.nextTab()
      this.next()
    },
    formWizardDatosContactoNextStep(datosContactoDto) {
      this.afiliadoDto.telefono = datosContactoDto.telefono
      this.afiliadoDto.email = datosContactoDto.email
      this.afiliadoDto.domicilioCalle = datosContactoDto.domicilioCalle
      this.afiliadoDto.domicilioNumero = datosContactoDto.domicilioNumero
      this.afiliadoDto.domicilioOficinaDepto = datosContactoDto.domicilioOficinaDepto
      this.afiliadoDto.codigoPostal = datosContactoDto.codigoPostal
      this.afiliadoDto.localidad.id = datosContactoDto.localidad.id
      this.afiliadoDto.provincia.id = datosContactoDto.provincia.id
      this.afiliadoDto.provincia.nombre = datosContactoDto.provincia.nombre
      this.afiliadoDto.anotaciones = datosContactoDto.anotaciones
      // this.$refs.refFormWizard.nextTab()
      this.next()
    },
    formWizardGrupoFamiliarNextStep(grupoFamiliar) {
      this.afiliadoDto.GrupoFamiliar = grupoFamiliar
      // this.$refs.refFormWizard.nextTab()
      this.next()
    },
    formWizardDocumentacionNextStep(documentacion) {
      this.afiliadoDto.documentos = documentacion
      // this.$refs.refFormWizard.nextTab()
      this.next()
    },
    next() {
      return this.$refs.refFormWizard.isLastStep === true ? this.formSubmitted() : this.$refs.refFormWizard.nextTab()
    },
    formWizardPrevioustStep() {
      this.$refs.refFormWizard.prevTab()
    },
    beforeChangeDatosPersonales() {
    },
    formSubmitted() {
      this.$router.push('/afiliados')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Carga completada',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    mounted() {
    },
  },
}
</script>
