import axios from 'axios'
import {
  getCategoriaSindical,
  getByIsActivoCategoriaSindical,
  postSaveCategoriaSindical,
  putUpdateCategoriaSindical,
} from '@/utils/paths'

const pathGetCategoriaSindical = getCategoriaSindical
const pathGetByIsActivoCategoriaSindical = getByIsActivoCategoriaSindical
const pathPostSaveCategoriaSindical = postSaveCategoriaSindical
const pathPutUpdateCategoriaSindical = putUpdateCategoriaSindical

export function categoriaSindicalResource() {
  function findAllCategoriaSindical() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetCategoriaSindical)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoCategoriaSindical() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoCategoriaSindical)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveCategoriaSindical(categoriaSindicalDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveCategoriaSindical, categoriaSindicalDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateCategoriaSindical(id, categoriaSindicalDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateCategoriaSindical + id, categoriaSindicalDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllCategoriaSindical,
    findAllByIsActivoCategoriaSindical,
    saveCategoriaSindical,
    updateCategoriaSindical,
  }
}
