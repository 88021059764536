<template>
  <div>
    <!-- modal -->
    <b-modal
      id="mdlNuevoDocumentoDigital"
      v-model="showDocumentoCategoriaModal"
      :title="'Documentaciones disponibles'"
      ok-title="Aceptar"
      cancel-title="Cancelar"
      ok-variant="primary"
      no-close-on-backdrop
      no-close-on-esc
      centered
      @ok="okModal"
      @close="closeModalDocumentacion"
      @cancel="closeModalDocumentacion"
    >
      <validation-observer ref="modalAddDocumentacion">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                id="frmTipoDocCat"
                label="Documentación"
              >
                <validation-provider
                  name="documentacion"
                  rules="required"
                >
                  <div slot-scope="{ errors, failedRules }">

                    <b-form-select
                      id="selectTipoDoc"
                      v-model="docuTipoSeleccionado"
                      v-b-tooltip.hover="'Seleccione el tipo de documento'"
                      :state="errors.length > 0 ? false : null"
                      rounded
                    >
                      <option
                        v-for="obj in tableDataDocumentoCategoria"
                        :key="obj.id"
                        :value="obj"
                      >
                        {{ obj.nombre }} {{ ' (Obligatorio)' }}
                      </option>
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          Seleccione
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >
                      Debe seleccionar tipo de documentación
                    </small>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                id="filAdjunto"
                label="Archivo"
              >
                <validation-provider
                  name="fileAdjunto"
                  rules="required"
                >
                  <div slot-scope="{ errors, failedRules }">
                    <b-form-file
                      ref="file-uploader"
                      v-model="file"
                      placeholder=""
                      :state="errors.length > 0 ? false : null"
                      :accept="config.extensionesAceptadas"
                      browse-text="Examinar"
                    />
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >
                      Debe seleccionar algun archivo
                    </small>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="d-flex justify-content-between">
          <b-button
            size="sm"
            squared
            variant="danger"
            @click="closeModalDocumentacion"
          >
            Cerrar
          </b-button>

          <b-button
            size="sm"
            squared
            variant="primary"
            @click="okModal"
          >
            Aceptar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  required,
} from '@validations'

export default {
  name: 'DocumentacionModal',
  components: {
  },
  props: {
    showDocumentoCategoriaModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    tableDataDocumentoCategoria: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      nameModulo: 'ABM_AFILIADOS',
      required,
      docuTipoSeleccionado: null,
      tableDataCategoria: [],
      config: {
        tamanioMaximoAdjunto: 10, // MB
        extensionesAceptadas: '.pdf, .doc, .txt, .png, .jpg, .jpng',
      },
      file: null,
    }
  },
  computed: {
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        user: 'currentUser',
      },
    ),
  },
  watch: {
    file() {
      if (this.file !== null) {
        let extensionArchivo = this.file.name.split('.').pop()
        if (extensionArchivo !== null) {
          extensionArchivo = extensionArchivo.toLowerCase()
        }
        //  && extensionArchivo === 'pdf'
        if (extensionArchivo !== null && this.config.extensionesAceptadas.includes(extensionArchivo)) {
          if (this.getMBfromBytes(this.file.size) >= 0 && this.getMBfromBytes(this.file.size) <= this.config.tamanioMaximoAdjunto) {
            if (this.file.name.length > 100) {
              this.toast('warning', null, 'El nombre del archivo no debe superar los 100 caracteres')
            } else {
              return
            }
          } else if (this.file.size === 0) {
            this.toast('warning', null, 'El archivo a adjuntar no debe estar vacío')
          } else {
            this.toast('warning', null, `El archivo a adjuntar debe tener un tamaño inferior a ${this.config.tamanioMaximoAdjunto} Mb`)
          }
        } else {
          this.toast('warning', null, `Solo se permite adjuntar archivos en los siguientes formatos ${this.config.extensionesAceptadas}`)
        }
        this.clearUpload()
      }
    },
  },
  created() {
    this.tableDataCategoria = this.tableDataDocumentoCategoria
  },
  methods: {
    clearUpload() {
      this.$refs['file-uploader'].reset()
      this.file = null
    },
    toast(type, title, html) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">${html}</p>`
      this.$swal({
        icon: type,
        title,
        html: htmlText,
        background: this.swalConfig.background,
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
      })
    },
    closeModalDocumentacion(bvModalEvt) {
      this.$emit('closeModalDocumentacion')
      bvModalEvt.preventDefault()
    },
    generarRandomId() {
      return `generated${Math.random().toString(36).substring(7)}`
    },
    okModal(bvModalEvt) {
      this.validationFormModalUploadFile()
      bvModalEvt.preventDefault()
    },
    validationFormModalUploadFile() {
      this.$refs.modalAddDocumentacion.validate().then(success => {
        if (success) {
          const afiliadoDocumento = {
            id: this.generarRandomId(),
            afiliadoAdherenteDocumentoCategoria: this.docuTipoSeleccionado,
            nombre: this.file.name,
            size: this.file.size,
            adjunto: this.file,
            tipo: this.file.type,
          }
          this.$emit('agregarDocumentacion', afiliadoDocumento)
        }
      })
    },
  },
}
</script>
