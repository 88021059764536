import axios from 'axios'
import {
  getDocumentoTipo,
} from '@/utils/paths'

const pathGetDocumentoTipo = getDocumentoTipo

export function documentoTipoResource() {
  function findAllDocumentoTipo() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetDocumentoTipo)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllDocumentoTipo,
  }
}
