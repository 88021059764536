import axios from 'axios'
import {
  getCategoriaLaboral,
  getByConvenioIdCategoriaLaboral,
  postSaveCategoriaLaboral,
  putUpdateCategoriaLaboral,
} from '@/utils/paths'

const pathGetCategoriaLaboral = getCategoriaLaboral
const pathGetByConvenioIdCategoriaLaboral = getByConvenioIdCategoriaLaboral
const pathPostSaveCategoriaLaboral = postSaveCategoriaLaboral
const pathPutUpdateCategoriaLaboral = putUpdateCategoriaLaboral

export function categoriaLaboralResource() {
  function findAllCategoriaLaboral() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetCategoriaLaboral)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findCategoriaLaboralByConvenio(convenioId) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByConvenioIdCategoriaLaboral + convenioId)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveCategoriaLaboral(categoriaLaboralDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveCategoriaLaboral, categoriaLaboralDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateCategoriaLaboral(id, categoriaLaboralDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateCategoriaLaboral + id, categoriaLaboralDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllCategoriaLaboral,
    findCategoriaLaboralByConvenio,
    saveCategoriaLaboral,
    updateCategoriaLaboral,
  }
}
