import axios from 'axios'
import {
  postUploadAfiliadoAdherenteDocumento,
  getListaAfiliadoAdherenteDocumento,
  getAdjuntoAfiliadoAdherenteDocumento,
  deleteAfiliadoAdherenteDocumento,
} from '@/utils/paths'

const pathPostUploadAfiliadoAdherenteDocumento = postUploadAfiliadoAdherenteDocumento
const pathGetListaAfiliadoAdherenteDocumento = getListaAfiliadoAdherenteDocumento
const pathGetAdjuntoAfiliadoAdherenteDocumento = getAdjuntoAfiliadoAdherenteDocumento
const pathDeleteAfiliadoAdherenteDocumento = deleteAfiliadoAdherenteDocumento

export function afiliadoAdjuntoAdherenteResource() {
  function uploadFileAttachmentAfiliado(afiliadoId, afiliadoAdherenteId, adjuntoCategoriaId, adjunto) {
    return new Promise((resolve, reject) => {
      const formData = new FormData() // eslint-disable-line
      formData.append('adjunto', adjunto)
      axios
        .post(`${pathPostUploadAfiliadoAdherenteDocumento}/${afiliadoId}/${afiliadoAdherenteId}/${adjuntoCategoriaId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getListadoAfiliadoDocumento(afiliadoAdherenteId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetListaAfiliadoAdherenteDocumento + afiliadoAdherenteId,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getAdjuntoByte(afiliadoAdherenteDocumentoId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAdjuntoAfiliadoAdherenteDocumento + afiliadoAdherenteDocumentoId, { responseType: 'blob' },
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function deleteDocumento(afiliadoAdherenteDocumentoId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(pathDeleteAfiliadoAdherenteDocumento + afiliadoAdherenteDocumentoId)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    uploadFileAttachmentAfiliado,
    getListadoAfiliadoDocumento,
    getAdjuntoByte,
    deleteDocumento,
  }
}
