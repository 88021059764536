<template>
  <div>
    <h4>
      {{ title }}
    </h4>
    <b-modal
      v-model="showAfiliadoAdherenteModal"
      :title="title"
      no-close-on-backdrop
      size="lg"
      hide-header-close
      @close="closeModal"
    >
      <validation-observer ref="refAdherente">
        <b-row>
          <b-col md="6">
            <b-form-group
              id="frmRelacionFamiliar"
              label="Parentesco"
            >
              <validation-provider
                name="parentesco"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">

                  <small v-if="isBusyBuscandoRelacionFamiliar">
                    <span
                      v-if="isBusyBuscandoRelacionFamiliar"
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    /> Buscando parentesco
                  </small>
                  <b-form-select
                    v-if="!isBusyBuscandoRelacionFamiliar"
                    id="selectRelacionFamiliar"
                    v-model="afiliadoAdherenteDto.relacionFamiliar.id"
                    v-b-tooltip.hover="'Seleccione el parentesco'"
                    :state="errors.length > 0 ? false : null"
                    :options="optionsRelacionFamiliar"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoRelacionFamiliar ? 'true' : 'false'"
                    :disabled="isBusyBuscandoRelacionFamiliar || !isEditable"
                    rounded
                  >
                    <template #first>
                      <b-form-select-option
                        v-if="!isBusyBuscandoRelacionFamiliar"
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required && !isBusyBuscandoRelacionFamiliar"
                    class="text-danger"
                  >
                    Parentesco obligatorio.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              id="frmTipoDocumento"
              label="Tipo documento"
            >
              <validation-provider
                name="tipoDocumento"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-select
                    id="selectTipoDocumento"
                    v-model="afiliadoAdherenteDto.documentoTipo.id"
                    v-b-tooltip.hover="'Seleccione el tipo de documento'"
                    :state="errors.length > 0 ? false : null"
                    :options="optionsTipoDocumento"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoTipoDocumento ? 'true' : 'false'"
                    :disabled="!isEditable"
                    rounded
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >
                    Tipo de documento obligatorio.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="frmNroDocumento"
              label="Número documento"
            >
              <validation-provider
                name="numeroDocumento"
                :rules="getNumeroDocumentoRules()"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtNroDocumento"
                    v-model="afiliadoAdherenteDto.numeroDocumento"
                    :state="errors.length > 0 ? false : null"
                    :disabled="!isEditable || shouldDisableNumeroDocumento"
                    autocomplete="off"
                    type="number"
                    maxlength="8"
                    oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Numero documento obligatorio.</small>
                  <small
                    v-if="ruleLongitudDoc"
                    class="text-danger"
                  >
                    El Numero de documento debe tener exactamente 8 caracteres.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              id="frmApellido"
              label="Apellidos"
            >
              <validation-provider
                name="apellido"
                rules="required|max:250|min:2"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtApellido"
                    v-model="afiliadoAdherenteDto.apellido"
                    :state="errors.length > 0 ? false : null"
                    autocomplete="off"
                    aria-describedby="input-live-help input-live-feedback"
                    maxlength="250"
                    :disabled="!isEditable"
                  />
                  <b-form-invalid-feedback id="input-live-feedback">
                    Ingrese al menos dos letras
                  </b-form-invalid-feedback>
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Apellido obligatorio.</small>
                  <small
                    v-if="failedRules.max"
                    class="text-danger"
                  >Máximo 250 caracteres.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="frmNombre"
              label="Nombres"
            >
              <validation-provider
                name="nombre"
                rules="required|max:250"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtNombre"
                    v-model="afiliadoAdherenteDto.nombre"
                    :state="errors.length > 0 ? false : null"
                    autocomplete="off"
                    maxlength="250"
                    :disabled="!isEditable"
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Nombre obligatorio.</small>
                  <small
                    v-if="failedRules.max"
                    class="text-danger"
                  >Máximo 250 caracteres.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              id="frmFechaNacimiento"
              label="Fecha de nacimiento"
            >
              <validation-provider
                name="FechaNacimiento"
                rules="required|min:8"
              >
                <div slot-scope="{ errors, failedRules }">
                  <cleave
                    id="date"
                    v-model="afiliadoAdherenteDto.fechaNacimiento"
                    class="form-control"
                    :class="{ 'is-invalid': errors.length > 0 }"
                    :raw="true"
                    :options="options.date"
                    autocomplete="off"
                    placeholder="fecha DD/MM/AAAA"
                    :disabled="!isEditable"
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Fecha de nacimiento obligatoria
                  </small>
                  <small
                    v-if="failedRules.min"
                    class="text-danger"
                  >Campo obligatorio
                  </small>
                  <small
                    v-if="fechaInvalida"
                    class="text-danger"
                  >La fecha debe ser menor al día de hoy</small>
                  <small
                    v-if="fechaRules"
                    class="text-danger"
                  >La fecha de nacimiento no puede ser mayor a 1 año. Verifique si es correcta, sino cambie el tipo de documento.</small>
                  <small
                    v-if="fechaMax"
                    class="text-danger"
                  >La edad no puede ser mayor a 99 años.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="frmGenero"
              label="Género"
            >
              <validation-provider
                name="genero"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-select
                    id="selectGenero"
                    v-model="afiliadoAdherenteDto.genero.id"
                    v-b-tooltip.hover="'Seleccione el género'"
                    :state="errors.length > 0 ? false : null"
                    :options="optionsGenero"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoGenero ? 'true' : 'false'"
                    :disabled="!isEditable"
                    rounded
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >
                    Género obligatorio.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <h5>Documentación Adherente</h5>
            <b-col md="12">
              <b-table
                id="tblProductos"
                ref="refTblProductos"
                :items="adjuntosList"
                :fields="fieldsDocumentos"
                show-empty
                small
                outlined
                sticky-header="70vh"
                :no-provider-sorting="false"
                :no-border-collapse="true"
                empty-text="No se encontraron resultados para esta búsqueda"
              >
                <template v-slot:table-busy>
                  <div class="text-center">
                    <b-spinner />
                  </div>
                </template>
                <template #cell(categoria)="row">
                  {{ row.item.afiliadoDocumentoCategoria.nombre }}
                </template>
                <template #cell(archivo)="row">
                  {{ row.item.nombre }}
                </template>
                <template #cell(actions)="row">
                  <b-button
                    v-b-tooltip.hover.right
                    variant="danger"
                    size="sm"
                    title="Eliminar adjunto"
                    :disabled="puedeSerBorrado()"
                    @click="quitarDoc(row.item, row.index)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                  <!--title="Descargar adjunto"-->
                  <b-button
                    v-if="puedeDescargarse(row.item)"
                    v-b-tooltip.hover
                    variant="info"
                    size="sm"
                    :disabled="row.item.isDownloading"
                    @click="descargarAdjunto(row.item)"
                  >
                    <font-awesome-icon
                      v-if="!row.item.isDownloading"
                      icon="fa-solid fa-file-arrow-down"
                    />
                    <span
                      v-if="row.item.isDownloading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="permisosUpd.includes(nameModulo)"
            md="12"
            class="text-right"
          >
            <b-button
              variant="primary"
              :disabled="!isEditable"
              @click="openModalAddDocumentacion"
            >
              Agregar documentación
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
      <div v-if="showDocumentoCategoriaModal">
        <grupo-familiar-documentacion-modal
          :show-documento-categoria-modal="showDocumentoCategoriaModal"
          :table-data-documento-categoria="afiliadoAdherenteDocumentos"
          @agregarDocumentacion="agregarDocumentacion"
          @closeModalDocumentacion="closeModalDocumentacion"
        />
      </div>
      <template #modal-footer>
        <div
          v-if="openModeModal != 'view'"
          class="d-flex justify-content-between"
        >
          <b-button
            size="sm"
            squared
            variant="danger"
            @click="closeModal"
          >
            Cancelar
          </b-button>
          <b-button
            size="sm"
            squared
            variant="primary"
            @click="agregarNuevoAdherente"
          >{{ tituloBotonAgregar }}
          </b-button>
        </div>
        <div
          v-if="openModeModal == 'view'"
          class="d-flex justify-content-between"
        >
          <b-button
            size="sm"
            squared
            variant="primary"
            @click="closeModal"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Cleave from 'vue-cleave-component'
import { generoResource } from '@/services/generoResource'
import { documentoTipoResource } from '@/services/documentoTipoResource'
import { fileManagement } from '@/utils/fileManagement'
import { afiliadoAdjuntoAdherenteResource } from '@/services/afiliadoAdjuntoAdherenteResource'
import { relacionFamiliarResource } from '@/services/relacionFamiliarResource'
import { afiliadoAdherenteDocumentoReglasResource } from '@/services/afiliadoAdherenteDocumentoReglasResource'
import GrupoFamiliarDocumentacionModal from './GrupoFamiliarDocumentacionModal.vue'

export default {
  components: {
    Cleave,
    GrupoFamiliarDocumentacionModal,
  },
  props: {
    modalDataAfiliadoAdherente: {
      type: Object,
      default: null,
      validator(value) {
        return value === null || typeof value === 'object'
      },
    },
    showAfiliadoAdherenteModal: {
      type: Boolean,
      required: true,
    },
    openModeModal: {
      type: String,
      required: true,
    },
    idAfiliado: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      nameModulo: 'ABM_AFILIADOS',
      afiliadoAdherenteDto: {
        id: null,
        afiliadoId: null,
        edad: null,
        relacionFamiliar: {
          id: null,
          nombre: null,
        },
        documentoTipo: {
          id: null,
          nombre: null,
        },
        numeroDocumento: null,
        apellido: null,
        nombre: null,
        fechaNacimiento: null,
        genero: {
          id: null,
          nombre: null,
        },
      },
      isBusyBuscandoRelacionFamiliar: false,
      isBusyBuscandoGenero: false,
      isBusyBuscandoTipoDocumento: false,
      showDocumentoCategoriaModal: false,
      fechaInvalida: false,
      fechaRules: false,
      fechaMax: false,
      ruleLongitudDoc: false,
      shouldDisableNumeroDocumento: false,
      addDocumentacion: false,
      adjuntosList: [],
      optionsRelacionFamiliar: [],
      optionsGenero: [],
      optionsTipoDocumento: [],
      optionsDocumentosTipo: [],
      afiliadoAdherenteDocumentoReglas: [],
      afiliadoAdherenteDocumentos: [],
      afiliadoAdherenteDocumentoCategoria: [],
      docsToBeRemoved: [],
      fieldsDocumentos: [
        { key: 'afiliadoAdherenteDocumentoCategoria.nombre', label: 'categoría' },
        { key: 'nombre', label: 'archivo' },
        { key: 'actions', label: 'acciones' },
      ],
      afiliadoDocumentoCategoria: [],
      options: {
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
      },
    }
  },
  computed: {
    isEditable() {
      return (this.openModeModal === 'new' || this.openModeModal === 'edit' || this.openModeModal === 'incompleteLoad' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
    tituloBotonAgregar() {
      return this.openModeModal === 'new' ? 'Agregar' : 'Modificar'
    },
    title() {
      return this.openModeModal === 'new' ? 'Nuevo Adherente' : 'Edición Adherente'
    },
  },
  watch: {
    'afiliadoAdherenteDto.numeroDocumento': {
      handler(newValue) {
        if (newValue !== null) {
          if (newValue.toString().length < 8) {
            this.ruleLongitudDoc = true
          } else {
            this.ruleLongitudDoc = false
          }
        }
      },
    },
    'afiliadoAdherenteDto.fechaNacimiento': {
      handler(newVal) {
        if (newVal && newVal.length === 8) {
          this.validarFechaNacimiento()
        }
      },
    },
    'afiliadoAdherenteDto.relacionFamiliar.id': {
      handler(newVal) {
        if (newVal !== this.optionsRelacionFamiliar.find(option => option.nombre === 'Hijo').id) {
          this.optionsTipoDocumento = this.optionsDocumentosTipo.filter(docTipo => docTipo.nombre !== 'SIN DOCUMENTO')
          if (this.afiliadoAdherenteDto.documentoTipo.id === this.optionsDocumentosTipo.find(option => option.nombre === 'SIN DOCUMENTO').id) {
            this.afiliadoAdherenteDto.documentoTipo.id = null
          }
        } else {
          this.optionsTipoDocumento = this.optionsDocumentosTipo
        }
      },
    },
    'afiliadoAdherenteDto.documentoTipo.id': {
      async handler(newVal) {
        if (newVal) {
          if (newVal === this.optionsDocumentosTipo.find(option => option.nombre === 'SIN DOCUMENTO').id) {
            this.afiliadoAdherenteDto.numeroDocumento = null
            this.shouldDisableNumeroDocumento = true
            this.ruleLongitudDoc = false
            if (this.afiliadoAdherenteDto.fechaNacimiento) {
              await this.validarFechaNacimiento()
            }
          } else {
            this.shouldDisableNumeroDocumento = false
            this.fechaRules = false
          }
        }
      },
    },
  },
  created() {
    this.iniciar()
  },
  methods: {
    async iniciar() {
      await this.getGeneroLista()
      await this.getRelacionFamiliarLista()
      await this.getTipoDocumentoLista()
      await this.getDocumentosPorReglas()
      if (this.modalDataAfiliadoAdherente && this.modalDataAfiliadoAdherente.id) {
        this.afiliadoAdherenteDto = { ...this.modalDataAfiliadoAdherente }
      }
      if (this.afiliadoAdherenteDto && this.afiliadoAdherenteDto.id) {
        if (!this.afiliadoAdherenteDto.id.toString().includes('generated')) {
          await this.getAfiliadoDocumentos()
        } else {
          this.adjuntosList = this.modalDataAfiliadoAdherente.adjuntos
        }
      }
    },
    toast(type, title, html) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">${html}</p>`
      this.$swal({
        icon: type,
        title,
        html: htmlText,
        background: this.swalConfig.background,
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
      })
    },
    generarRandomId() {
      return `generated${Math.random().toString(36).substring(7)}`
    },
    validarFechaNacimiento() {
      if (this.afiliadoAdherenteDto && this.afiliadoAdherenteDto.fechaNacimiento && this.afiliadoAdherenteDto.documentoTipo) {
        const dia = this.afiliadoAdherenteDto.fechaNacimiento.substr(0, 2)
        const mes = this.afiliadoAdherenteDto.fechaNacimiento.substr(2, 2)
        const año = this.afiliadoAdherenteDto.fechaNacimiento.substr(4, 4)

        const fechaIngresada = new Date(`${mes}/${dia}/${año}`)
        const fechaActual = new Date()
        this.fechaInvalida = fechaIngresada > fechaActual

        this.fechaRules = false
        if (this.afiliadoAdherenteDto.documentoTipo.id === this.optionsDocumentosTipo.find(option => option.nombre === 'SIN DOCUMENTO').id) {
          const fechaLimite = new Date()
          fechaLimite.setFullYear(fechaLimite.getFullYear() - 1)

          if (fechaIngresada < fechaLimite) {
            this.fechaRules = true
          }
        }
        if (!this.fechaInvalida) {
          this.calcularEdad()
        }
      }
    },
    getNumeroDocumentoRules() {
      if (this.shouldDisableNumeroDocumento) {
        return ''
      }
      return 'required'
    },
    async agregarNuevoAdherente() {
      return this.$refs.refAdherente.validate().then(async success => {
        if (success) {
          if (!this.fechaInvalida && !this.fechaMax && !this.fechaRules) {
            if (this.addDocumentacion || this.openModeModal !== 'new') {
              if (await this.validarRequisitos()) {
                if (this.docsToBeRemoved.length > 0) {
                  this.docsToBeRemoved.forEach(async item => {
                    await this.deleteDocs(item)
                  })
                  this.docsToBeRemoved = []
                }
                this.findByNombres()
                this.afiliadoAdherenteDto.fechaNacimiento = this.formatDateToJavaT(this.afiliadoAdherenteDto.fechaNacimiento, 'DDMMYYYY', 'DD/MM/YYYY')
                this.afiliadoAdherenteDto.adjuntos = this.adjuntosList
                if (this.afiliadoAdherenteDto.id === null) {
                  this.afiliadoAdherenteDto.id = this.generarRandomId()
                  this.$emit('agregarAfiliadoAdherente', this.afiliadoAdherenteDto)
                } else {
                  this.$emit('setAfiliadoAdherente', this.afiliadoAdherenteDto)
                }
              }
            } else {
              this.toast('warning', '', 'Falta cargar la documentación correspondiente')
            }
          } else {
            this.toast('warning', '', 'Debe revisar los datos ingresados.')
          }
        }
      })
    },
    closeModal() {
      this.$emit('closeModalAfiliadoAdherente')
    },
    findByNombres() {
      this.optionsGenero.forEach(element => {
        if (element.id === this.afiliadoAdherenteDto.genero.id) {
          this.afiliadoAdherenteDto.genero.nombre = element.nombre
        }
      })
      this.optionsRelacionFamiliar.forEach(element => {
        if (element.id === this.afiliadoAdherenteDto.relacionFamiliar.id) {
          this.afiliadoAdherenteDto.relacionFamiliar.nombre = element.nombre
        }
      })
      this.optionsTipoDocumento.forEach(element => {
        if (element.id === this.afiliadoAdherenteDto.documentoTipo.id) {
          this.afiliadoAdherenteDto.documentoTipo.nombre = element.nombre
        }
      })
    },
    async calcularEdad() {
      const dia = parseInt(this.afiliadoAdherenteDto.fechaNacimiento.substring(0, 2), 10)
      const mes = parseInt(this.afiliadoAdherenteDto.fechaNacimiento.substring(2, 4), 10) - 1
      const anio = parseInt(this.afiliadoAdherenteDto.fechaNacimiento.substring(4), 10)

      const fechaNacimiento = new Date(anio, mes, dia)
      const hoy = new Date()

      const edadMilisegundos = hoy - fechaNacimiento
      const edadFecha = new Date(edadMilisegundos)

      this.afiliadoAdherenteDto.edad = Math.abs(edadFecha.getUTCFullYear() - 1970)
      if (this.afiliadoAdherenteDto.edad > 99) {
        this.fechaMax = true
      } else {
        this.fechaMax = false
      }
      if (this.afiliadoAdherenteDto.edad <= 1 && this.afiliadoAdherenteDto.documentoTipo.id === this.optionsTipoDocumento.find(option => option.nombre === 'SIN DOCUMENTO').id) {
        this.shouldDisableNumeroDocumento = true
      }
    },
    quitarDoc(item, index) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">¿Desea quitar esta documentación ${item.nombre}? </p>`
      this.$swal({
        title: 'Quitar documentación',
        html: htmlText,
        icon: 'warning',
        background: this.swalConfig.background,
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          if (!item.id.toString().includes('generated')) {
            this.docsToBeRemoved.push(item)
            const adjuntoIndex = this.adjuntosList.findIndex(adjunto => adjunto.id === item.id)
            if (adjuntoIndex !== -1) {
              this.adjuntosList.splice(adjuntoIndex, 1)
            }
            // await this.deleteDocs(item)
          } else {
            this.adjuntosList.splice(index, 1)
          }
        }
      })
    },
    openModalAddDocumentacion() {
      this.$refs.refAdherente.validate().then(success => {
        if (success) {
          if (!this.fechaInvalida) {
            this.encontrarCategoriasCoincidentes()
          } else {
            this.toast('warning', '', 'Se debe revisar los datos ingresados.')
          }
        } else {
          this.toast('warning', '', 'Se debe completar todos los datos antes de agregar la documentación')
        }
      })
    },
    encontrarCategoriasCoincidentes() {
      this.addDocumentacion = true
      this.afiliadoAdherenteDocumentos = []
      const reglasPlanas = this.afiliadoAdherenteDocumentoReglas.flat()
      const reglasCoincidentes = reglasPlanas.filter(regla => (
        this.afiliadoAdherenteDto.relacionFamiliar.id === regla.relacionFamiliar.id
        && this.afiliadoAdherenteDto.documentoTipo.id === regla.documentoTipo.id
        && this.afiliadoAdherenteDto.edad >= regla.edadDesde
        && this.afiliadoAdherenteDto.edad <= regla.edadHasta
      ))
      if (reglasCoincidentes.length > 0) {
        reglasCoincidentes.forEach(regla => {
          this.afiliadoAdherenteDocumentos.push(regla.afiliadoAdherenteDocumentoCategoria)
          this.showDocumentoCategoriaModal = true
        })
      } else {
        this.toast('warning', '', 'No se encuentra documentación disponible para este parentesco y su edad.')
      }
    },
    closeModalDocumentacion() {
      this.showDocumentoCategoriaModal = false
    },
    agregarDocumentacion(docu) {
      this.showDocumentoCategoriaModal = false
      this.adjuntosList.push(docu)
    },
    async validarRequisitos() {
      await this.encontrarCategoriasCoincidentes()
      if (this.afiliadoAdherenteDocumentos.length === 0) {
        this.toast('warning', 'Datos incorrectos', 'Revise los datos ingresados. La edad del hijo supera los 24 años.')
        return false
      }
      const docsFaltantes = this.afiliadoAdherenteDocumentos.filter(afiliadoDocuCat => !this.adjuntosList.some(docAdjunto => docAdjunto.afiliadoAdherenteDocumentoCategoria.id === afiliadoDocuCat.id))
      if (docsFaltantes.length > 0) {
        this.toast('warning', 'Adjuntos', `Archivos pendientes de adjuntar: ${this.buildStringMessage(docsFaltantes)}`)
        return false
      }

      return true
    },
    buildStringMessage(detalleErrores) {
      this.customizeSweetAlert2()
      let message = '<ul>'
      detalleErrores.forEach(item => {
        message += `<li style="color: ${this.swalConfig.textColor}"> ${item.nombre} <br>`
        message += '</li>'
      })
      message += '</ul>'
      return message
    },
    async deleteDocs(docToRemoved) {
      this.isWorking = true
      afiliadoAdjuntoAdherenteResource().deleteDocumento(docToRemoved.id).then(() => {
        // const adjuntoIndex = this.adjuntosList.findIndex(adjunto => adjunto.id === res)
        // if (adjuntoIndex !== -1) {
        //   this.adjuntosList.splice(adjuntoIndex, 1)
        // }
        this.isWorking = false
      }).catch(error => {
        console.error('error al eliminar documento afiliado:', error)
      })
        .finally(() => {
          this.isWorking = false
        })
    },
    descargarAdjunto(docToDownload) {
      const docIndex = this.adjuntosList.findIndex(doc => doc.id === docToDownload.id)
      this.adjuntosList[docIndex].isDownloading = true
      afiliadoAdjuntoAdherenteResource().getAdjuntoByte(docToDownload.id).then(resBytes => {
        fileManagement().getAdjunto(resBytes, docToDownload.nombre, docToDownload.tipoAdjunto)
        this.adjuntosList[docIndex].isDownloading = false
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.adjuntosList[docIndex].isDownloading = false
        })
    },
    getAfiliadoDocumentos() {
      afiliadoAdjuntoAdherenteResource().getListadoAfiliadoDocumento(this.afiliadoAdherenteDto.id).then(res => {
        this.adjuntosList = res

        if (this.modalDataAfiliadoAdherente.adjuntos) {
          const generatedItems = this.modalDataAfiliadoAdherente.adjuntos.filter(item => item.id.toString().includes('generated'))
          generatedItems.forEach(item => {
            const newItem = {
              id: item.id,
              afiliadoAdherente: this.afiliadoAdherenteDto,
              afiliadoAdherenteDocumentoCategoria: item.afiliadoAdherenteDocumentoCategoria,
              pathFile: item.adjunto.$path,
              nombre: item.nombre,
              tipo: item.tipo,
              extension: item.extension,
              size: item.size,
            }
            const exists = this.adjuntosList.some(adjunto => adjunto.id === newItem.id)
            if (!exists) {
              this.adjuntosList.push(newItem)
            }
          })
        }
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {

        })
    },
    getDocumentosPorReglas() {
      this.afiliadoAdherenteDocumentoReglas = []
      afiliadoAdherenteDocumentoReglasResource().findByDocumentosPorReglas().then(res => {
        this.afiliadoAdherenteDocumentoReglas.push(res)
      }).catch(error => {
        console.error('error:', error)
      })
    },
    puedeDescargarse(doc) {
      let puede
      if (doc.id !== null && doc.id !== undefined) {
        const contieneIdGenerado = doc.id.toString().includes('generated')
        puede = doc.id !== null && contieneIdGenerado === false
      }
      return puede
    },
    puedeSerBorrado() {
      return !this.isEditable
    },
    getRelacionFamiliarLista() {
      this.isBusyBuscandoRelacionFamiliar = true
      return relacionFamiliarResource().findAllByIsActivoRelacionFamiliar().then(result => {
        this.optionsRelacionFamiliar = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoRelacionFamiliar = false
        })
    },
    getGeneroLista() {
      this.isBusyBuscandoGenero = true
      return generoResource().findAllByIsActivoGenero().then(result => {
        this.optionsGenero = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoGenero = false
        })
    },
    getTipoDocumentoLista() {
      this.isBusyBuscandoTipoDocumento = true
      return documentoTipoResource().findAllDocumentoTipo().then(result => {
        this.optionsDocumentosTipo = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoTipoDocumento = false
        })
    },
  },
}
</script>
