import axios from 'axios'
import {
  getAllRelacionFamiliar,
  getByIsActivoRelacionFamiliar,
  postSaveRelacionFamiliar,
  putUpdateRelacionFamiliar,
} from '@/utils/paths'

const pathGetRelacionFamiliar = getAllRelacionFamiliar
const pathGetByIsActivoRelacionFamiliar = getByIsActivoRelacionFamiliar
const pathPostSaveRelacionFamiliar = postSaveRelacionFamiliar
const pathPutUpdateRelacionFamiliar = putUpdateRelacionFamiliar

export function relacionFamiliarResource() {
  function findAllRelacionFamiliar() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetRelacionFamiliar)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoRelacionFamiliar() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoRelacionFamiliar)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveRelacionFamiliar(relacionFamiliarDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveRelacionFamiliar, relacionFamiliarDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateRelacionFamiliar(id, relacionFamiliarDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateRelacionFamiliar + id, relacionFamiliarDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllRelacionFamiliar,
    findAllByIsActivoRelacionFamiliar,
    saveRelacionFamiliar,
    updateRelacionFamiliar,
  }
}
