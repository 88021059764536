<template>
  <div>
    <h4>
      {{ title }}
    </h4>
    <b-modal
      v-model="showAfiliadoEmpleadorModal"
      title="Detalles del Empleador"
      no-close-on-backdrop
      size="lg"
      hide-header-close
      @close="closeModal"
    >
      <validation-observer ref="refDatosLaborales">
        <b-row>
          <b-col md="4">
            <b-form-group
              id="frmCuitEmpleador"
              label="C.U.I.T. Empleador"
            >
              <validation-provider
                name="cuit"
                rules="required|min:11"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="block"
                    v-maska="bindedObject"
                    :value="datosLaboralesDto.empleador.cuit"
                    :state="errors.length > 0 ? false:null"
                    data-maska="##-########-#"
                    autocomplete="off"
                    :disabled="!isEditable"
                    placeholder="xx-xxxxxxxx-x"
                    @maska="onMaska"
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >C.U.I.T. obligatorio.
                  </small>
                  <small
                    v-if="failedRules.min"
                    class="text-danger"
                  >C.U.I.T. incompleto.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmRazonSocialEmpleador"
              label="Razón social"
            >
              <validation-provider
                name="razonSocialEmpleador"
                rules="required|max:250|min:2"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtRazonSocialEmpleador"
                    v-model="datosLaboralesDto.empleador.razonSocial"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                    aria-describedby="input-live-help input-live-feedback"
                    maxlength="250"
                    disabled
                  />
                  <b-form-invalid-feedback id="input-live-feedback">
                    Ingrese al menos dos letras
                  </b-form-invalid-feedback>
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Razón social obligatoria.</small>
                  <small
                    v-if="failedRules.max"
                    class="text-danger"
                  >Máximo 250 caracteres.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmNombreFantasiaEmpleador"
              label="Nombre fantasía"
            >
              <validation-provider
                name="nombreFantasiaEmpleador"
                rules="required|max:250"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtNombreFantasiaEmpleador"
                    v-model="datosLaboralesDto.empleador.nombreFantasia"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                    maxlength="250"
                    disabled
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Nombre obligatorio.</small>
                  <small
                    v-if="failedRules.max"
                    class="text-danger"
                  >Máximo 250 caracteres.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              id="frmDomicilioLaboral"
              label="Domicilio laboral"
            >
              <validation-provider
                name="domicilioLaboral"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">

                  <small v-if="isBusyBuscandoDomicilioLaboral">
                    <span
                      v-if="isBusyBuscandoDomicilioLaboral"
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    /> Buscando domicilio laboral
                  </small>
                  <b-form-select
                    v-if="!isBusyBuscandoDomicilioLaboral"
                    id="selectDomicilioLaboral"
                    v-model="datosLaboralesDto.domicilioLaboral.id"
                    v-b-tooltip.hover="'Seleccione el domicilio laboral'"
                    :state="errors.length > 0 ? false:null"
                    :options="optionsDomicilioLaboral"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoDomicilioLaboral ? 'true' : 'false'"
                    :disabled="isBusyBuscandoDomicilioLaboral || !isEditable"
                    rounded
                  >
                    <template #first>
                      <b-form-select-option
                        v-if="!isBusyBuscandoDomicilioLaboral"
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required && !isBusyBuscandoDomicilioLaboral"
                    class="text-danger"
                  >
                    Domicilio laboral obligatorio.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmLegajo"
              label="Legajo"
            >
              <validation-provider name="legajo">
                <div slot-scope="{ }">
                  <b-form-input
                    id="txtLegajo"
                    v-model="datosLaboralesDto.legajo"
                    autocomplete="off"
                    aria-describedby="input-live-help input-live-feedback"
                    maxlength="250"
                    :disabled="!isEditable"
                  />
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmFechaIngreso"
              label="Fecha de ingreso"
            >
              <validation-provider name="FechaIngreso">
                <div slot-scope="{}">
                  <cleave
                    id="date"
                    v-model="datosLaboralesDto.fechaIngreso"
                    class="form-control"
                    :raw="true"
                    :options="options.date"
                    autocomplete="off"
                    placeholder="fecha DD/MM/AAAA"
                    :disabled="!isEditable"
                  />
                  <small
                    v-if="fechaInvalida"
                    class="text-danger"
                  >Debe ser menor o igual a la fecha del día.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              id="frmConvenioTrabajo"
              label="Convenio de trabajo"
            >
              <validation-provider
                name="convenioTrabajo"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">

                  <small v-if="isBusyBuscandoConvenioTrabajo">
                    <span
                      v-if="isBusyBuscandoConvenioTrabajo"
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    /> Buscando convenio de trabajo
                  </small>
                  <b-form-select
                    v-if="!isBusyBuscandoConvenioTrabajo"
                    id="selectConvenioTrabajo"
                    v-model="datosLaboralesDto.convenioTrabajo.id"
                    v-b-tooltip.hover="'Seleccione el tipo de documento'"
                    :state="errors.length > 0 ? false:null"
                    :options="optionsConvenioTrabajo"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoConvenioTrabajo ? 'true' : 'false'"
                    :disabled="isBusyBuscandoConvenioTrabajo || !isEditable"
                    rounded
                    @change="convevionChanged"
                  >
                    <template #first>
                      <b-form-select-option
                        v-if="!isBusyBuscandoConvenioTrabajo"
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required && !isBusyBuscandoConvenioTrabajo"
                    class="text-danger"
                  >
                    Convenio de trabajo obligatorio.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmCategoriaLaboral"
              label="Categoría laboral"
            >
              <validation-provider
                name="categoriaLaboral"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">

                  <small v-if="isBusyBuscandoCategoriaLaboral">
                    <span
                      v-if="isBusyBuscandoCategoriaLaboral"
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    /> Buscando categoría laboral
                  </small>
                  <b-form-select
                    v-if="!isBusyBuscandoCategoriaLaboral"
                    id="selectCategoriaLaboral"
                    v-model="datosLaboralesDto.categoriaLaboral.id"
                    v-b-tooltip.hover="'Seleccione la categoría laboral'"
                    :state="errors.length > 0 ? false:null"
                    :options="optionsCategoriaLaboral"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoCategoriaLaboral ? 'true' : 'false'"
                    :disabled="isBusyBuscandoCategoriaLaboral || !isEditable"
                    rounded
                  >
                    <template #first>
                      <b-form-select-option
                        v-if="!isBusyBuscandoCategoriaLaboral"
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required && !isBusyBuscandoCategoriaLaboral"
                    class="text-danger"
                  >
                    Categoría laboral obligatoria.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              id="frmEstadoLaboral"
              label="Estado laboral"
            >
              <validation-provider
                name="estadoLaboral"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">

                  <small v-if="isBusyBuscandoEstadoLaboral">
                    <span
                      v-if="isBusyBuscandoEstadoLaboral"
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    /> Buscando estado laboral
                  </small>
                  <b-form-select
                    v-if="!isBusyBuscandoEstadoLaboral"
                    id="selectEstadoLaboral"
                    v-model="datosLaboralesDto.estadoLaboral.id"
                    v-b-tooltip.hover="'Seleccione el estado laboral'"
                    :state="errors.length > 0 ? false:null"
                    :options="optionsEstadoLaboral"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoEstadoLaboral ? 'true' : 'false'"
                    :disabled="isBusyBuscandoEstadoLaboral || !isEditable"
                    rounded
                  >
                    <template #first>
                      <b-form-select-option
                        v-if="!isBusyBuscandoEstadoLaboral"
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required && !isBusyBuscandoEstadoLaboral"
                    class="text-danger"
                  >
                    Estado laboral obligatorio.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div
          v-if="openModeModal != 'view'"
          class="d-flex justify-content-between"
        >
          <b-button
            size="sm"
            squared
            variant="danger"
            @click="closeModal"
          >Cancelar</b-button>
          <b-button
            size="sm"
            squared
            variant="primary"
            @click="agregarNuevoEmpleador"
          >{{ tituloBotonAgregar }} </b-button>
        </div>
        <div
          v-if="openModeModal == 'view'"
          class="d-flex justify-content-between"
        >
          <b-button
            size="sm"
            squared
            variant="primary"
            @click="closeModal"
          >Cerrar</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { domicilioLaboralResource } from '@/services/domicilioLaboralResource'
import { convenioTrabajoResource } from '@/services/convenioTrabajoResource'
import { categoriaLaboralResource } from '@/services/categoriaLaboralResource'
import { estadoLaboralResource } from '@/services/estadoLaboralResource'
import { empleadoresResource } from '@/services/empleadoresResource'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    Cleave,
  },
  props: {
    modalDataAfiliadoEmpleador: {
      type: Object,
      default: null,
      validator(value) {
        return value === null || typeof value === 'object'
      },
    },
    showAfiliadoEmpleadorModal: {
      type: Boolean,
      required: true,
    },
    openModeModal: {
      type: String,
      required: true,
    },
    idAfiliado: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      datosLaboralesDto: {
        id: null,
        afiliadoId: null,
        empleador: {
          id: null,
          razonSocial: null,
          nombreFantasia: null,
          cuit: null,
        },

        domicilioLaboral: {
          id: null,
          nombre: null,
        },
        legajo: null,
        fechaIngreso: null,
        convenioTrabajo: {
          id: null,
          nombre: null,
        },
        categoriaLaboral: {
          id: null,
          nombre: null,
        },
        estadoLaboral: {
          id: null,
          nombre: null,
        },
      },
      isBusyBuscandoEstadoLaboral: false,
      isBusyBuscandoCategoriaLaboral: false,
      isBusyBuscandoConvenioTrabajo: false,
      isBusyBuscandoDomicilioLaboral: false,
      fechaInvalida: false,
      optionsDomicilioLaboral: [],
      optionsEstadoLaboral: [],
      optionsCategoriaLaboral: [],
      optionsConvenioTrabajo: [],
      options: {
        block: {
          blocks: [2, 8, 1],
          uppercase: true,
        },
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        customDelimiter: {
          delimiters: ['-', '-'],
          blocks: [2, 8, 1],
          uppercase: true,
          numericOnly: true,
        },
      },
      bindedObject: {
        masked: '',
        unmasked: '',
        completed: false,
      },
    }
  },
  computed: {
    isEditable() {
      return (this.openModeModal === 'new' || this.openModeModal === 'edit' || this.openModeModal === 'incompleteLoad' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
    tituloBotonAgregar() {
      return this.openModeModal === 'new' ? 'Agregar' : 'Modificar'
    },
    title() {
      return this.openModeModal === 'new' ? 'Nuevo Empleador' : 'Edición Empleador'
    },
  },
  watch: {
    'datosLaboralesDto.empleador.cuit': {
      handler(newCuit, oldCuit) {
        if (oldCuit !== null) {
          if (newCuit && newCuit.length === 11) {
            if (this.datosLaboralesDto.cuit !== null && this.datosLaboralesDto.cuit !== newCuit) {
              this.getEmpleadorByCuit(newCuit)
            }
          } else {
            this.datosLaboralesDto.empleador.razonSocial = null
            this.datosLaboralesDto.empleador.nombreFantasia = null
            this.datosLaboralesDto.domicilioLaboral.id = null
            this.optionsDomicilioLaboral = []
          }
        }
      },

    },
    'datosLaboralesDto.fechaIngreso': {
      handler(newVal) {
        if (newVal && newVal.length === 8) {
          this.validarFechaIngreso()
        }
      },
    },
  },
  created() {
    if (this.modalDataAfiliadoEmpleador !== null) {
      this.datosLaboralesDto = { ...this.modalDataAfiliadoEmpleador }
      this.getDomicilioLaboralLista(this.datosLaboralesDto.empleador.id)
    }
    if (this.datosLaboralesDto.convenioTrabajo.id !== null) {
      this.getCategoriaLaboralLista(this.datosLaboralesDto.convenioTrabajo.id)
      this.getEstadoLaboralLista(this.datosLaboralesDto.convenioTrabajo.id)
    }
    this.getConvenioTrabajoLista()
  },
  methods: {
    generarRandomId() {
      return `generated${Math.random().toString(36).substring(7)}`
    },
    onMaska(val) {
      this.datosLaboralesDto.empleador.cuit = val.detail.unmasked
    },
    agregarNuevoEmpleador() {
      return this.$refs.refDatosLaborales.validate().then(success => {
        if (success) {
          this.FindByDomicilioLaboral(this.datosLaboralesDto.domicilioLaboral.id)
          this.findByNombres()
          this.datosLaboralesDto.fechaIngreso = this.formatDateToJavaT(this.datosLaboralesDto.fechaIngreso, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
          if (this.datosLaboralesDto.id === null) {
            this.datosLaboralesDto.id = this.generarRandomId()
            this.$emit('agregarAfiliadoEmpleador', this.datosLaboralesDto)
          } else {
            this.$emit('setAfiliadoEmpleador', this.datosLaboralesDto)
          }
        }
      })
    },
    closeModal() {
      return this.$refs.refDatosLaborales.validate().then(success => {
        if (success) {
          this.$emit('closeModalAfiliadoEmpleador')
        }
      })
    },
    findByNombres() {
      this.optionsConvenioTrabajo.forEach(element => {
        if (element.id === this.datosLaboralesDto.convenioTrabajo.id) {
          this.datosLaboralesDto.convenioTrabajo.nombre = element.nombre
        }
      })
      this.optionsCategoriaLaboral.forEach(element => {
        if (element.id === this.datosLaboralesDto.categoriaLaboral.id) {
          this.datosLaboralesDto.categoriaLaboral.nombre = element.nombre
        }
      })
      this.optionsEstadoLaboral.forEach(element => {
        if (element.id === this.datosLaboralesDto.estadoLaboral.id) {
          this.datosLaboralesDto.estadoLaboral.nombre = element.nombre
        }
      })
    },
    FindByDomicilioLaboral(domicilioLaboralId) {
      this.isBusyBuscandoDomicilioLaboral = true
      domicilioLaboralResource().findById(domicilioLaboralId)
        .then(resultDomicilioLaboral => {
          this.datosLaboralesDto.domicilioLaboral = resultDomicilioLaboral
        })
        .catch(error => {
          console.error('error:', error)
        })
        .finally(() => {
          this.isBusyBuscandoDomicilioLaboral = false
        })
    },
    convevionChanged(convenioId) {
      this.datosLaboralesDto.estadoLaboral.id = null
      this.datosLaboralesDto.categoriaLaboral.id = null
      this.getEstadoLaboralLista(convenioId)
      this.getCategoriaLaboralLista(convenioId)
    },
    validarFechaIngreso() {
      const fechaIngresada = new Date(`${this.datosLaboralesDto.fechaIngreso.substr(2, 2)}/${this.datosLaboralesDto.fechaIngreso.substr(0, 2)}/${this.datosLaboralesDto.fechaIngreso.substr(4, 4)}`)
      const fechaActual = new Date().setHours(23, 59, 59, 999)
      this.fechaInvalida = fechaIngresada > fechaActual
    },
    getEmpleadorByCuit(cuit) {
      this.isBusyBuscandoEmpleador = true
      empleadoresResource().findEmpleadorByCuit(cuit)
        .then(resultEmpleador => {
          if (resultEmpleador.razonSocial !== null && resultEmpleador.nombreFantasia && resultEmpleador.empleadorEstado.nombre === 'Activo') {
            this.datosLaboralesDto.empleador.razonSocial = resultEmpleador.razonSocial
            this.datosLaboralesDto.empleador.id = resultEmpleador.id
            this.datosLaboralesDto.empleador.nombreFantasia = resultEmpleador.nombreFantasia
            // this.getDomicilioLaboralLista(resultEmpleador.id)
            if (resultEmpleador.domiciliosLaborales.length > 0) {
              // calle + número + oficina dpto + cp + localidad
              resultEmpleador.domiciliosLaborales.forEach(domicilio => {
                let domi = null
                if (domicilio.domicilioCalle !== 'VIRTUAL') {
                  domi = {
                    id: domicilio.id,
                    nombre: `${domicilio.domicilioCalle}  ${domicilio.domicilioNumero} ${domicilio.domicilioOficinaDepto ? ` ${domicilio.domicilioOficinaDepto}` : ''} - ${domicilio.codigoPostal} - ${domicilio.localidad.nombre} - ${domicilio.provincia.nombre}`,
                  }
                } else {
                  domi = {
                    id: domicilio.id,
                    nombre: `${domicilio.domicilioCalle} - ${domicilio.delegacion.nombre}`,
                  }
                }
                this.optionsDomicilioLaboral.push(domi)
              })
            }
          } else {
            this.datosLaboralesDto.empleador.cuit = null
          }
        })
        .catch(error => {
          console.error('error:', error)
        })
        .finally(() => {
          this.isBusyBuscandoEmpleador = false
        })
    },
    getDomicilioLaboralLista(empleadorId) {
      this.isBusyBuscandoDomicilioLaboral = true
      domicilioLaboralResource().findByEmpleadorId(empleadorId)
        .then(resultDomicilioLaboral => {
          resultDomicilioLaboral.forEach(domicilio => {
            let domi = null
            if (domicilio.domicilioCalle !== 'VIRTUAL') {
              domi = {
                id: domicilio.id,
                nombre: `${domicilio.domicilioCalle}  ${domicilio.domicilioNumero} ${domicilio.domicilioOficinaDepto ? ` ${domicilio.domicilioOficinaDepto}` : ''} - ${domicilio.codigoPostal} - ${domicilio.localidad.nombre} - ${domicilio.provincia.nombre}`,
              }
            } else {
              domi = {
                id: domicilio.id,
                nombre: `${domicilio.domicilioCalle} - ${domicilio.delegacion.nombre}`,
              }
            }
            this.optionsDomicilioLaboral.push(domi)
          })
        })
        .catch(error => {
          console.error('error:', error)
        })
        .finally(() => {
          this.isBusyBuscandoDomicilioLaboral = false
        })
    },
    getConvenioTrabajoLista() {
      this.isBusyBuscandoConvenioLaboral = true
      convenioTrabajoResource().findAllByIsActivoConvenioLaboral().then(result => {
        this.optionsConvenioTrabajo = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoConvenioLaboral = false
        })
    },
    getCategoriaLaboralLista(convenioId) {
      this.isBusyBuscandoCategoriaLaboral = true
      categoriaLaboralResource().findCategoriaLaboralByConvenio(convenioId).then(result => {
        this.optionsCategoriaLaboral = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoCategoriaLaboral = false
        })
    },
    getEstadoLaboralLista(convenioId) {
      this.isBusyBuscandoEstadoLaboral = true
      estadoLaboralResource().findEstadoLaboralByConvenio(convenioId).then(result => {
        this.optionsEstadoLaboral = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEstadoLaboral = false
        })
    },
  },
}
</script>
