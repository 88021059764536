/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getDocumentosPorCategoriaSindical,
  getReglasPorDocumentoSindical,
  postReglaSindical,
  putReglaSindical,
  deleteReglaSindical,

} from '@/utils/paths'

const pathGetReglasPorDocumentoSindical = getReglasPorDocumentoSindical
const pathGetDocumentosPorCategoriaSindical = getDocumentosPorCategoriaSindical
const pathPostReglaSindical = postReglaSindical
const pathPutReglaSindical = putReglaSindical
const pathDeleteReglaSindical = deleteReglaSindical

export function afiliadoCatSindicalDocumentoResource() {
  function getReglasPorDocumentoSindical(documentoSindicalId) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetReglasPorDocumentoSindical + documentoSindicalId)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function getDocumentosPorCategoriaSindical(categoriaSindicalId) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetDocumentosPorCategoriaSindical + categoriaSindicalId)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveReglaSindical(reglaSindical) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostReglaSindical, reglaSindical)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateReglaSindical(id, reglaSindical) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutReglaSindical + id, reglaSindical)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function deleteReglaSindicalCategoria(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(pathDeleteReglaSindical + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    getReglasPorDocumentoSindical,
    getDocumentosPorCategoriaSindical,
    saveReglaSindical,
    updateReglaSindical,
    deleteReglaSindicalCategoria,
  }
}
