<template>
  <div>
    <h4 v-if="openMode == 'new' || openMode == 'incompleteLoad'">
      {{ title }}
    </h4>
    <b-overlay
      :show="isWorking || isSearching || isLoading"
      :rounded="overlayConfig.rounded"
      :variant="overlayConfig.variant"
      :opacity="overlayConfig.opacity"
      :blur="overlayConfig.blur"
    >
      <template #overlay>
        <div class="mt-2">
          <div style="text-align: center">
            <span class="spinner-border " />
          </div>
          <div style="text-align: center">
            <small>{{ overlayConfig.message }}</small>
          </div>
        </div>
      </template>

      <validation-observer ref="refDatosPersonales">
        <b-row>
          <b-col md="10">
            <b-row>
              <b-col
                v-if="!isWizard"
                md="12"
              >
                <h5>
                  <b>DATOS PERSONALES</b>
                </h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="frmCuil"
                  label="C.U.I.L."
                >
                  <validation-provider
                    name="cuil"
                    rules="required|min:11"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="block"
                        v-maska="bindedObject"
                        :value="datosPersonalesDto.cuil"
                        :state="errors.length > 0 ? false:null"
                        data-maska="##-########-#"
                        autocomplete="off"
                        :disabled="!isEditable"
                        placeholder="xx-xxxxxxxx-x"
                        @maska="onMaska"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >C.U.I.L. obligatorio.
                      </small>
                      <small
                        v-if="failedRules.min"
                        class="text-danger"
                      >C.U.I.L. incompleto.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="frmTipoDocumento"
                  label="Tipo documento"
                >
                  <validation-provider
                    name="tipoDocumento"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-select
                        id="selectTipoDocumento"
                        v-model="datosPersonalesDto.documentoTipo.id"
                        v-b-tooltip.hover="'Seleccione el tipo de documento'"
                        :state="errors.length > 0 ? false:null"
                        :options="optionsTipoDocumento"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoTipoDocumento ? 'true' : 'false'"
                        :disabled="!isEditable"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >
                        Tipo de documento obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="frmNroDocumento"
                  label="Número documento"
                >
                  <validation-provider
                    name="nombre"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtNroDocumento"
                        v-model="datosPersonalesDto.numeroDocumento"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                        type="number"
                        maxlength="8"
                        oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        :disabled="!isEditable"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Numero documento obligatorio.</small>
                      <small
                        v-if="ruleLongitudDoc"
                        class="text-danger"
                      >
                        El Numero de documento debe tener exactamente 8 caracteres.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="frmApellido"
                  label="Apellido"
                >
                  <validation-provider
                    name="apellido"
                    rules="required|max:250|min:2"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtApellido"
                        v-model="datosPersonalesDto.apellido"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                        aria-describedby="input-live-help input-live-feedback"
                        maxlength="250"
                        :disabled="!isEditable"
                      />
                      <b-form-invalid-feedback id="input-live-feedback">
                        Ingrese al menos dos letras
                      </b-form-invalid-feedback>
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Apellido obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 250 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="frmNombre"
                  label="Nombres"
                >
                  <validation-provider
                    name="nombre"
                    rules="required|max:250"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtNombre"
                        v-model="datosPersonalesDto.nombre"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                        maxlength="250"
                        :disabled="!isEditable"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Nombre obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 250 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="frmFechaNacimiento"
                  label="Fecha de nacimiento"
                >
                  <validation-provider
                    name="FechaNacimiento"
                    rules="required|min:8"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <cleave
                        id="date"
                        v-model="datosPersonalesDto.fechaNacimiento"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                        :raw="true"
                        :options="options.date"
                        autocomplete="off"
                        placeholder="fecha DD/MM/AAAA"
                        :disabled="!isEditable"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Fecha de nacimiento obligatoria
                      </small>
                      <small
                        v-if="failedRules.min"
                        class="text-danger"
                      >Campo obligatorio
                      </small>
                      <small
                        v-if="fechaInvalida"
                        class="text-danger"
                      >La fecha debe ser menor al día de hoy</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="frmGenero"
                  label="Género"
                >
                  <validation-provider
                    name="genero"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-select
                        id="selectGenero"
                        v-model="datosPersonalesDto.genero.id"
                        v-b-tooltip.hover="'Seleccione el género'"
                        :state="errors.length > 0 ? false:null"
                        :options="optionsGenero"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoGenero ? 'true' : 'false'"
                        :disabled="!isEditable"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >
                        Género obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="frmEstadoCivil"
                  label="Estado civil"
                >
                  <validation-provider
                    name="estadoCivil"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-select
                        id="selectEstadoCivil"
                        v-model="datosPersonalesDto.estadoCivil.id"
                        v-b-tooltip.hover="'Seleccione el estado civil'"
                        :state="errors.length > 0 ? false:null"
                        :options="optionsEstadoCivil"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoEstadoCivil ? 'true' : 'false'"
                        :disabled="!isEditable"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >
                        Estado civil obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="!isWizard"
                md="12"
              >
                <h5>
                  <b>DATOS AFILIACIÓN</b>
                </h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="frmDelegacion"
                  label="Delegación"
                >
                  <validation-provider
                    name="delegacion"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-select
                        id="selectDelegacion"
                        v-model="datosPersonalesDto.delegacion.id"
                        v-b-tooltip.hover="'Seleccione la delegación'"
                        :state="errors.length > 0 ? false:null"
                        :options="optionsDelegacion"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoDelegacion ? 'true' : 'false'"
                        :disabled="!isEditable"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >
                        Delegación obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="frmCategoriaSindical"
                  label="Categoría sindical"
                >
                  <validation-provider
                    name="categoriaSindical"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-select
                        id="selectCategoriaSindical"
                        v-model="datosPersonalesDto.categoriaSindical.id"
                        v-b-tooltip.hover="'Seleccione la delegación'"
                        :state="errors.length > 0 ? false:null"
                        :options="optionsCategoriaSindical"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoCategoriaSindical ? 'true' : 'false'"
                        :disabled="!isEditable"
                        rounded
                        @change="catSindFunction"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >
                        Categoría sindical obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="frmCbu"
                  label="C.B.U."
                >
                  <!--rules="required"-->
                  <validation-provider
                    name="cbu"
                    :rules="ruleCbu"
                  >
                    <!--@input="restrictToNumbers"-->
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtCbu"
                        v-model="datosPersonalesDto.cbu"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                        type="number"
                        maxlength="22"
                        oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        :disabled="!isEditable"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Numero de C.B.U. obligatorio.</small>
                      <small
                        v-if="ruleLongitudCbu"
                        class="text-danger"
                      >
                        El C.B.U. debe tener exactamente 22 caracteres.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="frmEstadoAfiliacion"
                  label="Estado afiliación"
                >
                  <validation-provider
                    name="estadoAfiliación"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-select
                        id="selectEstadoAfiliacion"
                        v-model="datosPersonalesDto.afiliacionEstado.id"
                        v-b-tooltip.hover="'Seleccione el estado de afiliación'"
                        :state="errors.length > 0 ? false:null"
                        :options="optionsEstadoAfiliacion"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoEstadoAfiliacion ? 'true' : 'false'"
                        :disabled="openMode !== 'edit'"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.requiredn"
                        class="text-danger"
                      >
                        Estado de afiliación obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="openMode !== 'new' && openMode !== 'incompleteLoad'"
                md="4"
              >
                <b-form-group
                  id="frmFechaAlta"
                  label="Fecha de alta"
                >
                  <validation-provider
                    name="FechaAlta"
                    rules="required|min:8"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <cleave
                        id="date"
                        v-model="datosPersonalesDto.fechaAlta"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                        :raw="true"
                        :options="options.date"
                        autocomplete="off"
                        placeholder="fecha DD/MM/AAAA"
                        disabled
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Fecha de alta obligatoria
                      </small>
                      <small
                        v-if="failedRules.min"
                        class="text-danger"
                      >Campo obligatorio
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="isBaja"
                md="4"
              >
                <b-form-group
                  id="frmMotivoBaja"
                  label="Motivo de baja"
                >
                  <validation-provider
                    name="motivoBaja"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-select
                        id="selectMotivoBaja"
                        v-model="datosPersonalesDto.motivoBaja.id"
                        v-b-tooltip.hover="'Seleccione el motivo de baja'"
                        :state="errors.length > 0 ? false:null"
                        :options="optionsMotivoBaja"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoMotivoBaja ? 'true' : 'false'"
                        :disabled="!isEditable"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >
                        Motivo de baja obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="2">
            <b-row class="text-center">
              <b-col md="12">
                <b-avatar
                  ref="previewAfiAvatar"
                  v-b-tooltip.hover="isEditable ? getMensaje : ''"
                  button
                  variant="outline-secondary"
                  :src="datosPersonalesDto.logo"
                  size="150px"
                  :square="true"
                  @click="openLogoInput"
                >
                  <h4 v-if="!datosPersonalesDto.logo">
                    Foto
                  </h4>
                </b-avatar>
              </b-col>
            </b-row>
            <b-row class="text-center">
              <b-col md="12">
                <b-button
                  v-if="datosPersonalesDto.logo && isEditable"
                  v-b-tooltip.hover="'Quitar foto'"
                  variant="danger"
                  class="w-100 m-0"
                  squared
                  size="sm"
                  :disabled="!isEditable"
                  @click="confirmDelete"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </b-col>
            </b-row>

            <input
              ref="logoAfiliadoInput"
              key="logoAfiliadoInputKey"
              type="file"
              accept="image/*"
              style="display: none"
              :disabled="!isEditable"
              @change="handleLogoChange"
            >
          </b-col>
          <b-col md="1">
            <div
              style="float: right;"
              class="btn-group"
              role="group"
              aria-label="Basic example"
            >
              <b-button
                v-if="permisosUpd.includes(nameModulo) && showBtnEdit"
                id="btnEditAfiliado"
                v-b-tooltip.hover
                variant="light"
                squared
                title="Editar Afiliado"
                data-toggle="button"
                aria-pressed="true"
                autocomplete="on"
                size="sm"
                :disabled="afiliadoDto.id !== undefined"
                @click="updateDatosPersonales()"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="!isWizard"
            md="12"
          >
            <h5>
              <b>EMPLEADOR ACTUAL</b>
            </h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="!isWizard"
            md="12"
          >
            <b-table
              id="tblAfiliadoEmpleador"
              ref="refTblAfiliadoEmpleador"
              :items="tableDataAfiliadoEmpleador"
              :busy="isWorking"
              :fields="fieldsAfiliadoEmpleador"
              outlined
              show-empty
              small
              hover
              sticky-header="70vh"
              :no-border-collapse="true"
              empty-text="No existe empleador cargado"
              disabled
            >
              <template v-slot:table-busy>
                <div class="text-center">
                  <b-spinner />
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
    <hr>
    <b-row>
      <b-col md="4">
        <b-button
          id="btnVolverAfiliado"
          v-b-tooltip.hover
          title="Volver a listado afiliados"
          variant="light"
          squared
          @click="()=>backAfiliado()"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
      </b-col>
      <b-col
        md="2"
        class="text-right px-0"
      >
        <b-button
          v-if="isWizard"
          id="btnPasoAnterior"
          v-b-tooltip.hover
          title="Anterior"
          variant="outline-primary"
          squared
          style="primary"
          :disabled="isWorking || isFirstStep"
          @click="anteriorWizard()"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
      </b-col>
      <b-col
        md="2"
        class="text-left  px-0"
      >
        <b-button
          v-if="isWizard"
          id="btnPasoPosterior"
          v-b-tooltip.hover
          squared
          variant="outline-primary"
          title="Siguiente"
          type="submit"
          :disabled="(isWorking || isSearching) || !isEditable"
          @click.prevent="posteriorWizard()"
        >
          <span />
          <feather-icon
            v-if="!isWorking"
            icon="ArrowRightIcon"
          />
          <span
            v-if="isWorking"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
      <b-col
        v-if="!isWizard"
        md="4"
        class="text-right"
      >
        <b-button
          v-if="permisosUpd.includes(nameModulo) && isEditable"
          id="btnGuardarDatosPersonales"
          v-b-tooltip.hover
          class="text-end"
          variant="primary"
          squared
          title="Guardar datos personales"
          type="submit"
          :disabled="!isEditable"
          @click.prevent="validarFormulario"
        >
          <font-awesome-icon
            v-if="!isWorking"
            icon="fa-solid fa-floppy-disk"
          />
          <span
            v-if="isWorking"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Cleave from 'vue-cleave-component'
import { estadoCivilResource } from '@/services/estadoCivilResource'
import { documentoTipoResource } from '@/services/documentoTipoResource'
import { categoriaSindicalResource } from '@/services/categoriaSindicalResource'
import { afiliacionEstadoResource } from '@/services/afiliacionEstadoResource'
import { afiliadoResource } from '@/services/afiliadoResource'
import { afiliadoEmpleadorResource } from '@/services/afiliadoEmpleadorResource'
import { delegacionResource } from '@/services/delegacionResource'
import { motivoBajaResource } from '@/services/motivoBajaResource'
import { generoResource } from '@/services/generoResource'

export default {
  components: {
    Cleave,

  },
  props: {
    afiliadoDto: {
      type: Object,
      required: false,
      default: null,
    },
    /* datosPersonales: {
      type: Object,
      required: false,
      default: null,
    }, */
    isWizard: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFirstStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    openMode: {
      type: String,
      required: true,
    },
    editarEnableDisable: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    isLastStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    idAfiliado: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      title: 'Nuevo Afiliado',
      nameModulo: 'ABM_AFILIADOS',
      logoAfiliadoInputKey: 0,
      showBtnEdit: false,
      isWorking: false,
      isSearching: false,
      maxLogoSize: 2500000,
      ruleCbu: null,
      ruleLongitudCbu: false,
      ruleLongitudDoc: false,
      fechaInvalida: false,
      datosPersonalesOirignalDto: null,
      tableDataAfiliadoEmpleador: null,
      fieldsAfiliadoEmpleador: [
        {
          key: 'empleador.cuit', label: 'C.U.I.T.', thStyle: { width: '20%' },
        },
        {
          key: 'empleador.razonSocial', label: 'Razón Social', thStyle: { width: '20%' },
        },
        {
          key: 'categoriaLaboral.nombre', label: 'Categoría', thStyle: { width: '30%' },
        },
        {
          key: 'estadoLaboral.nombre', label: 'Estado', thStyle: { width: '30%' },
        },
      ],
      datosPersonalesDto: {
        id: null,
        cuil: null,
        documentoTipo: {
          id: null,
          nombre: null,
        },
        numeroDocumento: null,
        apellido: null,
        nombre: null,
        nombreApellidoCustom: null,
        fechaNacimiento: null,
        estadoCivil: {
          id: null,
          nombre: null,
        },
        categoriaSindical: {
          id: null,
          nombre: null,
        },
        genero: {
          id: null,
          nombre: null,
        },
        cbu: null,
        afiliacionEstado: {
          id: null,
          nombre: null,
        },
        fechaAlta: null,
        fechaBaja: null,
        delegacion: {
          id: null,
          nombre: null,
        },
        motivoBaja: {
          id: null,
          nombre: null,
        },
        logo: null,
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
      optionsTipoDocumento: [],
      optionsGenero: [],
      optionsEstadoCivil: [],
      optionsDelegacion: [],
      optionsCategoriaSindical: [],
      optionsEstadoAfiliacion: [],
      optionsMotivoBaja: [],
      isBusyBuscandoEstadoCivil: false,
      isBusyBuscandoGenero: false,
      isBusyBuscandoTipoDocumento: false,
      isBusyBuscandoMotivoBaja: false,
      isBusyBuscandoCategoriaSindical: false,
      isBusyBuscandoDelegacion: false,
      isBusyBuscandoEstadoAfiliacion: false,
      options: {
        block: {
          blocks: [2, 8, 1],
          uppercase: true,
        },
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        customDelimiter: {
          delimiters: ['-', '-'],
          blocks: [2, 8, 1],
          uppercase: true,
          numericOnly: true,
        },
      },
      bindedObject: {
        masked: '',
        unmasked: '',
        completed: false,
      },
    }
  },
  computed: {
    isBaja() {
      return this.datosPersonalesDto.afiliacionEstado.id === 2
    },
    isLoading() {
      return this.isBusyBuscandoDelegacion || this.isBusyBuscandoEstadoCivil || this.isBusyBuscandoGenero || this.isBusyBuscandoTipoDocumento || this.isBusyBuscandoMotivoBaja || this.isBusyBuscandoCategoriaSindical
        || this.isBusyBuscandoCategoriaSindical || this.isBusyBuscandoEstadoAfiliacion
    },
    getMensaje() {
      return this.datosPersonalesDto.logo ? 'Cambiar foto' : 'Agregar foto'
    },
    isEditable() {
      return (this.openMode === 'new' || this.openMode === 'edit' || this.openMode === 'incompleteLoad' || this.editarEnableDisable)
    },
    condition() {
      return ((this.openMode === 'edit' && this.isEditable) || (this.openMode === 'view' && this.isEditable))
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        currentUser: 'getCurrentUser',
      },
    ),
  },
  watch: {
    'datosPersonalesDto.cuil': {
      handler(newCuil, oldCuil) {
        if (oldCuil !== null) {
          if (newCuil && newCuil.length === 11) {
            if ((this.datosPersonalesOirignalDto.cuil !== null && this.datosPersonalesOirignalDto.cuil !== newCuil) || this.isEditable) {
              this.verificarCuil(newCuil)
            }
          }
        }
      },
    },
    'datosPersonalesDto.fechaNacimiento': {
      handler(newVal) {
        if (newVal && newVal.length === 8) {
          this.validarFechaNacimiento()
        }
      },
    },
    'datosPersonalesDto.cbu': {
      handler(newValue) {
        if (newValue !== '') {
          if (newValue.toString().length < 22) {
            this.ruleLongitudCbu = true
          } else {
            this.ruleLongitudCbu = false
          }
        } else {
          this.ruleLongitudCbu = false
        }
      },
    },
    'datosPersonalesDto.numeroDocumento': {
      handler(newValue) {
        if (newValue.toString().length < 8) {
          this.ruleLongitudDoc = true
        } else {
          this.ruleLongitudDoc = false
        }
      },
    },
  },
  async created() {
    this.datosPersonalesOirignalDto = { ...this.afiliadoDto }
    this.datosPersonalesDto = { ...this.afiliadoDto }
    await this.iniciarBusquedaOptions()
    this.iniciarSelecciones()
    if (this.idAfiliado !== null) {
      this.buscarEmpleadorActual()
    }
  },
  methods: {
    async iniciarBusquedaOptions() {
      this.overlayConfig.message = 'Cargarndo opciones ...'
      const prom1 = this.getDelegacionLista()
      const prom2 = this.getTipoDocumentoLista()
      const prom3 = this.getGeneroLista()
      const prom4 = this.getEstadoCivilLista()

      const prom5 = this.getCategoriaSindicalLista()
      const prom6 = this.getEstadoAfiliacionLista()
      const prom7 = this.getMotivoBajaLista()
      return Promise.all([prom1, prom2, prom3, prom4, prom5, prom6, prom7])
        .then(() => {
        }).catch(error => {
          console.error('error:', error)
        }).finally(() => {
          this.overlayConfig.message = ''
        })
    },
    iniciarSelecciones() {
      if (this.openMode === 'new') {
        // codigo = 1 = Alta
        const objEstadoAfiliacion = this.optionsEstadoAfiliacion.find(obj => obj.codigo === '1')
        this.datosPersonalesDto.afiliacionEstado.id = objEstadoAfiliacion.id
      }
    },
    catSindFunction(categoriaSindicalId) {
      if (this.optionsCategoriaSindical.length > 0) {
        const catSindical = this.optionsCategoriaSindical.filter(item => item.codigo === '2')[0]
        if (catSindical.id === categoriaSindicalId) {
          this.ruleCbu = 'required'
          this.datosPersonalesDto.cbu = ''
        } else {
          this.ruleCbu = null
          this.datosPersonalesDto.cbu = ''
        }
      }
    },
    validarFechaNacimiento() {
      const fechaIngresada = new Date(`${this.datosPersonalesDto.fechaNacimiento.substr(2, 2)}/${this.datosPersonalesDto.fechaNacimiento.substr(0, 2)}/${this.datosPersonalesDto.fechaNacimiento.substr(4, 4)}`)
      const fechaActual = new Date()
      this.fechaInvalida = fechaIngresada > fechaActual
    },
    restrictToNumbers(event) {
      if (event && event.target) {
        const input = event.target
        if (input.value) {
          input.value = input.value.replace(/\D/g, '') // Remove non-numeric characters
        }
      }
    },
    mostrarOpcionesCuilExistente(afiVerificadoDto) {
      this.customizeSweetAlert2()
      if (this.openMode === 'new' || this.openMode === 'edit' || this.isEditable) {
        if (afiVerificadoDto.cargaCompletada === true) {
          const htmlText = `<p style="color:${this.swalConfig.textColor}">¿Desea visualizar el afiliado?</p>`
          this.$swal({
            title: 'El CUIL ya esta dado de alta',
            // text: '¿Desea visualizar el empleador?',
            html: htmlText,
            icon: 'warning',
            background: this.swalConfig.background,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            customClass: {
              confirmButton: 'btn btn-primary square',
              cancelButton: 'btn btn-danger square',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$router.push(`/afiliado/view/${afiVerificadoDto.id}`)
            } else {
              this.$router.push('/afiliados/')
            }
          })
        } else {
          const htmlText = `<p style="color:${this.swalConfig.textColor}">Faltan completar datos, ¿desea hacerlo ahora?</p>`
          this.$swal({
            title: 'El CUIL está en proceso de alta',
            // text: '¿faltan completar campos, desea hacerlo ahora?',
            html: htmlText,
            icon: 'warning',
            background: this.swalConfig.background,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            customClass: {
              confirmButton: 'btn btn-primary square',
              cancelButton: 'btn btn-danger square',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$router.push(`/afiliado/incompleteLoad/${afiVerificadoDto.id}`)
            } else {
              this.$router.push('/afiliados/')
            }
          })
        }
      }
    },
    verificarCuil(cuil) {
      this.isSearching = true
      this.overlayConfig.message = 'Verificando C.U.I.L'
      afiliadoResource().verificarCuilAfiliado(cuil).then(afiVerificadoDto => {
        if (afiVerificadoDto.id !== null) {
          this.mostrarOpcionesCuilExistente(afiVerificadoDto)
        }
      }).catch(error => {
        console.error('Error al verificar CUIL:', error)
      })
        .finally(() => {
          this.overlayConfig.message = ''
          this.isSearching = false
        })
    },
    onMaska(val) {
      this.datosPersonalesDto.cuil = val.detail.unmasked
    },
    backAfiliado() {
      this.$router.push('/afiliados')
    },
    anteriorWizard() {
      this.$emit('previous-step', this.afiliadoDto)
    },
    posteriorWizard() {
      this.validarFormulario()
    },
    validarFormulario() {
      return this.$refs.refDatosPersonales.validate().then(success => {
        if (success) {
          if (!this.ruleLongitudCbu && !this.fechaInvalida && !this.ruleLongitudDoc) {
            if (this.datosPersonalesDto.id !== null) {
              // update
              this.updateDatosPersonales()
            } else {
              // insert
              this.saveDatosPersonales()
            }
          }
        }
      })
    },
    saveDatosPersonales() {
      this.isWorking = true
      const datosPersonalesDto = { ...this.datosPersonalesDto }
      datosPersonalesDto.fechaNacimiento = this.formatDateToJavaT(datosPersonalesDto.fechaNacimiento, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
      datosPersonalesDto.fechaAlta = null
      datosPersonalesDto.fechaBaja = null
      afiliadoResource().saveDatosPersonales(datosPersonalesDto).then(resDtoDatosPersonales => {
        this.datosPersonalesDto.id = resDtoDatosPersonales.id
        this.datosPersonalesDto.fechaAlta = resDtoDatosPersonales.fechaAlta
        this.$emit('next-step', this.datosPersonalesDto)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    updateDatosPersonales() {
      this.isWorking = true
      const datosPersonalesDto = { ...this.datosPersonalesDto }
      datosPersonalesDto.fechaNacimiento = this.formatDateToJavaT(datosPersonalesDto.fechaNacimiento, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
      datosPersonalesDto.fechaAlta = this.formatDateToJavaT(datosPersonalesDto.fechaAlta, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
      afiliadoResource().updateDatosPersonales(datosPersonalesDto).then(resUpdateDtoDatosPersonales => {
        this.$emit('next-step', resUpdateDtoDatosPersonales)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    getTipoDocumentoLista() {
      this.isBusyBuscandoTipoDocumento = true
      return documentoTipoResource().findAllDocumentoTipo().then(result => {
        this.optionsTipoDocumento = result.filter(docTipo => docTipo.nombre !== 'SIN DOCUMENTO')
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoTipoDocumento = false
        })
    },
    getGeneroLista() {
      this.isBusyBuscandoGenero = true
      return generoResource().findAllByIsActivoGenero().then(result => {
        this.optionsGenero = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoGenero = false
        })
    },
    getEstadoCivilLista() {
      this.isBusyBuscandoEstadoCivil = true
      return estadoCivilResource().findAllByIsActivoEstadoCivil().then(result => {
        this.optionsEstadoCivil = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEstadoCivil = false
        })
    },
    getDelegacionLista() {
      this.isBusyBuscandoDelegacion = true
      return delegacionResource().findAllByIsActivoDelegacion().then(result => {
        this.optionsDelegacion = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoDelegacion = false
        })
    },
    getCategoriaSindicalLista() {
      this.isBusyBuscandoCategoriaSindical = true
      return categoriaSindicalResource().findAllByIsActivoCategoriaSindical().then(result => {
        this.optionsCategoriaSindical = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoCategoriaSindical = false
        })
    },
    getEstadoAfiliacionLista() {
      this.isBusyBuscandoEstadoAfiliacion = true
      return afiliacionEstadoResource().findAllByIsActivoAfiliacionEstado().then(result => {
        this.optionsEstadoAfiliacion = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEstadoAfiliacion = false
        })
    },
    getMotivoBajaLista() {
      this.isBusyBuscandoMotivoBaja = true
      return motivoBajaResource().findAllByIsActivoMotivoBaja().then(result => {
        this.optionsMotivoBaja = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoMotivoBaja = false
        })
    },
    openLogoInput() {
      // if (this.isEditable) {
      // this.logoAfiliadoInputKey += 1
      this.$refs.logoAfiliadoInput.click()
      // }
    },

    handleLogoChange(event) {
      const file = event.target.files[0]
      if (file) {
        if (file.size > this.maxLogoSize) {
          this.customizeSweetAlert2()
          const htmlText = `<p style="color:${this.swalConfig.textColor}">El tamaño del archivo debe ser menor a 2,5 MB.</p>`
          this.$swal({
            title: 'Tamaño de foto excede el límite',
            html: htmlText,
            icon: 'warning',
            background: this.swalConfig.background,
            confirmButtonText: 'Aceptar',
            customClass: {
              confirmButton: 'btn btn-primary square',
            },
            buttonsStyling: false,
          })
          return
        }
        // Para mostrar una vista previa del logo antes de cargarlo
        const reader = new FileReader()
        reader.onload = e => {
          this.datosPersonalesDto.logo = e.target.result
        }
        reader.readAsDataURL(file)
      }
    },
    confirmDelete() {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">¿Desea Eliminar la Foto?</p>`
      this.$swal({
        title: 'Eliminar Foto',
        // text: '¿Desea Eliminar el Logo?',
        html: htmlText,
        icon: 'warning',
        background: this.swalConfig.background,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.datosPersonalesDto.logo = null
        }
      })
    },
    buscarEmpleadorActual() {
      this.isWorking = true
      afiliadoEmpleadorResource().findByAfiliadoId(this.idAfiliado)
        .then(resultsFindByidAfiliado => {
          this.tableDataAfiliadoEmpleador = resultsFindByidAfiliado
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isWorking = false
        })
    },
  },
}
</script>
