import axios from 'axios'
import {
  getEstadoLaboral,
  getByConvenioIdEstadoLaboral,
  postSaveEstadoLaboral,
  putUpdateEstadoLaboral,
} from '@/utils/paths'

const pathGetEstadoLaboral = getEstadoLaboral
const pathGetByConvenioIdEstadoLaboral = getByConvenioIdEstadoLaboral
const pathPostSaveEstadoLaboral = postSaveEstadoLaboral
const pathPutUpdateEstadoLaboral = putUpdateEstadoLaboral

export function estadoLaboralResource() {
  function findAllEstadoLaboral() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetEstadoLaboral)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findEstadoLaboralByConvenio(convenioId) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByConvenioIdEstadoLaboral + convenioId)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveEstadoLaboral(estadoLaboralDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveEstadoLaboral, estadoLaboralDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateEstadoLaboral(id, estadoLaboralDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateEstadoLaboral + id, estadoLaboralDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllEstadoLaboral,
    findEstadoLaboralByConvenio,
    saveEstadoLaboral,
    updateEstadoLaboral,
  }
}
