<template>
  <div>
    <b-card class="m-0">
      <b-row>
        <b-col md="12">
          <b-form-group label="Domicilio laboral">
            <h5>{{ domicilioLaboral }}</h5>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label="Fecha ingreso">
            <h5>{{ convertFechaIngresoFormat(detalleAfiliadoEmpleadorDto.fechaIngreso) }}</h5>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Legajo">
            <h5>{{ detalleAfiliadoEmpleadorDto.legajo }}</h5>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group label="Convenio de trabajo">
            <h5>{{ detalleAfiliadoEmpleadorDto.convenioTrabajo.nombre }}</h5>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Categoría laboral">
            <h5>{{ detalleAfiliadoEmpleadorDto.categoriaLaboral.nombre }}</h5>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Estado laboral">
            <h5>{{ detalleAfiliadoEmpleadorDto.estadoLaboral.nombre }}</h5>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script
>

export default {
  components: {
  },
  props: {
    detalleAfiliadoEmpleador: {
      type: Object,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {
      detalleAfiliadoEmpleadorDto: {},
      domicilioLaboral: null,
    }
  },
  computed: {
  },
  created() {
    this.detalleAfiliadoEmpleadorDto = { ...this.detalleAfiliadoEmpleador }
    this.formatDomicilioLaboral()
  },
  methods: {
    formatDomicilioLaboral() {
      if (this.detalleAfiliadoEmpleadorDto.domicilioLaboral) {
        const domicilio = this.detalleAfiliadoEmpleadorDto.domicilioLaboral
        let domi = null

        if (domicilio.domicilioCalle !== 'VIRTUAL') {
          domi = {
            nombre: `${domicilio.domicilioCalle}  ${domicilio.domicilioNumero} ${domicilio.domicilioOficinaDepto ? ` ${domicilio.domicilioOficinaDepto}` : ''} - ${domicilio.codigoPostal} - ${domicilio.localidad.nombre}, ${domicilio.provincia.nombre}`,
          }
        } else {
          domi = {
            nombre: `${domicilio.domicilioCalle} - ${domicilio.delegacion.nombre}`,
          }
        }
        this.domicilioLaboral = domi.nombre
      }
    },
    convertFechaIngresoFormat(fechaIngreso) {
      // Convierte la fecha desde "DD-MM-YYYY HH:mm" a "DD/MM/YYYY"
      const parts = fechaIngreso.split(' ')
      const dateParts = parts[0].split('-')
      return `${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`
    },
  },
}
</script>
