import axios from 'axios'
import {
  getAfiliacionEstado,
  getByIsActivoAfiliacionEstado,
  postSaveAfiliacionEstado,
  putUpdateAfiliacionEstado,
} from '@/utils/paths'

const pathGetAfiliado = getAfiliacionEstado
const pathGetByIsActivoAfiliacionEstado = getByIsActivoAfiliacionEstado
const pathPostSaveAfiliacionEstado = postSaveAfiliacionEstado
const pathPutUpdateAfiliacionEstado = putUpdateAfiliacionEstado

export function afiliacionEstadoResource() {
  function findAllAfiliacionEstado() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetAfiliado)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoAfiliacionEstado() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoAfiliacionEstado)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveAfiliacionEstado(afiliacionEstadoDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveAfiliacionEstado, afiliacionEstadoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateAfiliacionEstado(id, afiliacionEstadoDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateAfiliacionEstado + id, afiliacionEstadoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllAfiliacionEstado,
    findAllByIsActivoAfiliacionEstado,
    saveAfiliacionEstado,
    updateAfiliacionEstado,
  }
}
