import axios from 'axios'
import {
  getConvenioTrabajo,
  getByIsActivoConvenioTrabajo,
  postConvenioTrabajo,
  putConvenioTrabajo,
} from '@/utils/paths'

const pathGetConvenioTrabajo = getConvenioTrabajo
const pathGetByIsActivoConvenioTrabajo = getByIsActivoConvenioTrabajo
const pathPostConvenioTrabajo = postConvenioTrabajo
const pathPutConvenioTrabajo = putConvenioTrabajo

export function convenioTrabajoResource() {
  function findAllConvenioLaboral() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetConvenioTrabajo)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoConvenioLaboral() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoConvenioTrabajo)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveConvenioTrabajo(ConvenioTrabajoDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostConvenioTrabajo, ConvenioTrabajoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateConvenioTrabajo(id, ConvenioTrabajoDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutConvenioTrabajo + id, ConvenioTrabajoDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllConvenioLaboral,
    findAllByIsActivoConvenioLaboral,
    saveConvenioTrabajo,
    updateConvenioTrabajo,
  }
}
