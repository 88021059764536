import axios from 'axios'
import {
  postAllAdherente,
  putAllAdherente,
  postAdherente,
  putAdherente,
  getByAdherente,
  deleteAdherente,
} from '@/utils/paths'

const pathPostAllAdherente = postAllAdherente
const pathPutAllAdherente = putAllAdherente
const pathPostAdherente = postAdherente
const pathPutAdherente = putAdherente
const pathgetByAdherente = getByAdherente
const pathDeleteAdherente = deleteAdherente

export function afiliadoAdherenteResource() {
  function saveAllAdherente(afiliadoAdherente) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostAllAdherente, afiliadoAdherente)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateAllAdherente(afiliadoAdherente) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutAllAdherente, afiliadoAdherente)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveAdherente(afiliadoAdherente) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostAdherente, afiliadoAdherente)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateAdherente(afiliadoAdherente) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutAdherente, afiliadoAdherente)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findByAfiliadoAdherente(idAfiliado) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathgetByAdherente + idAfiliado)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function deleteAfiliadoAdherente(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(pathDeleteAdherente + id)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    saveAllAdherente,
    updateAllAdherente,
    saveAdherente,
    updateAdherente,
    findByAfiliadoAdherente,
    deleteAfiliadoAdherente,
  }
}
